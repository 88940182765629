import { getPlayer } from "./videoPlayer";
var addStickyVideoTimestamp = null;
var THEO_PLAYER_IS_PLAYING_CLASS = "vjs-playing";
var observers = [];
export default function stickyVideo() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  if (CHANNEL_DESKTOP && !shouldBecomeSticky()) return;
  var article = context.getElementById("article");
  if (!article) return;
  var videoWrapper = article.getElementsByClassName("video-player__wrapper")[0];
  if (!videoWrapper) return;
  var intersectionThreshold = 0.5;
  var observer = new window.IntersectionObserver(viewPortUpdate, {
    threshold: intersectionThreshold
  });
  observer.observe(videoWrapper);
  var playerId = getMediaId(videoWrapper);
  observers[playerId] = observer;
  function viewPortUpdate(entries) {
    entries.forEach(function (entry) {
      var _isTheoPlayer$classLi;
      var isAboveTop = entry.boundingClientRect.top < 0;
      var target = entry.target;
      var video = target.getElementsByClassName("video")[0];
      var isTheoPlayer = videoWrapper.getElementsByClassName("theo-player")[0];
      var player = !isTheoPlayer ? getPlayer(playerId) : null;
      if (!player && !isTheoPlayer) return;
      var isTheoPlayerPlaying = isTheoPlayer === null || isTheoPlayer === void 0 || (_isTheoPlayer$classLi = isTheoPlayer.classList) === null || _isTheoPlayer$classLi === void 0 ? void 0 : _isTheoPlayer$classLi.contains(THEO_PLAYER_IS_PLAYING_CLASS);
      var isVideoPlayerPlaying = player === null || player === void 0 ? void 0 : player.isPlaying;
      if (isAboveTop && !video.classList.contains("sticky-video")) {
        var stickyVideoEl = isVideoSticky(context);
        if (isTheoPlayerPlaying || isVideoPlayerPlaying) {
          addStickyVideoTimestamp = Date.now();
          disableTransformOfSlideshowItem(article);
          video.classList.add("sticky-video");
          if (video.getElementsByClassName("video__caption")[0]) {
            setHidden(video, true);
          } else {
            window.setTimeout(function () {
              setHidden(video, true);
            }, 100);
          }
          var closeBtn = video.getElementsByClassName("sticky-video__close-button")[0];
          if (closeBtn) {
            closeBtn.addEventListener("click", function (e) {
              return closeBtnHandler(e, isTheoPlayer);
            });
            if (CHANNEL_DESKTOP) {
              var index = 1;
              closeBtn.setAttribute("tabindex", index);
            }
          }
          if (stickyVideoEl) {
            changeStickyVideo(stickyVideoEl, video);
          }
        }
        return;
      }
      if (entry.boundingClientRect.bottom >= 0 && video.classList.contains("sticky-video")) {
        if (!addStickyVideoTimestamp || Date.now() - addStickyVideoTimestamp > 150) {
          closeVideo(video);
        }
      }
    });
  }
}
function isVideoSticky(context) {
  var videoElms = context.getElementsByClassName("video");
  for (var i = 0, len = videoElms.length; i < len; i++) {
    if (videoElms[i].classList.contains("sticky-video")) {
      return videoElms[i];
    }
  }
  return false;
}
function changeStickyVideo(isStickyEl, currentPlayingEl) {
  var stickyVideoId = isStickyEl.getElementsByClassName("video__player")[0].getAttribute("data-media-id");
  var currentPlayerID = currentPlayingEl.getElementsByClassName("video__player")[0].getAttribute("data-media-id");
  if (stickyVideoId !== currentPlayerID) {
    isStickyEl.classList.remove("sticky-video");
    setHidden(isStickyEl, false);
  }
}
function closeBtnHandler(e, isTheoPlayer) {
  var el = e.target.parentNode;
  var video = el.classList.contains("video") ? el : el.parentNode;
  closeVideo(video, isTheoPlayer);
  var playerId = getMediaId(video);
  var observer = observers[playerId];
  if (!observer) return;
  observer.unobserve(video.parentNode);
}
function closeVideo(video, isTheoPlayer) {
  var _window$playerManager;
  video.classList.add("sticky-video--slide-up");
  if (isTheoPlayer) (_window$playerManager = window.playerManager) === null || _window$playerManager === void 0 || (_window$playerManager = _window$playerManager.getPlayer(0)) === null || _window$playerManager === void 0 || _window$playerManager.theo.pause();
  window.setTimeout(function () {
    setHidden(video, false);
    video.classList.remove("sticky-video", "sticky-video--slide-up");
  }, 400);
}
function addOrRemoveHidden(el, shouldAdd) {
  if (!el) return;
  if (shouldAdd) {
    el.classList.add("hidden");
  } else {
    el.classList.remove("hidden");
  }
}
function setHidden(video, isSticky) {
  addOrRemoveHidden(video.getElementsByClassName("video__caption")[0], isSticky ? true : false);
  addOrRemoveHidden(video.getElementsByClassName("sticky-video__description")[0], isSticky ? false : true);
  addOrRemoveHidden(video.getElementsByClassName("sticky-video__close-button")[0], isSticky ? false : true);
}
function getMediaId(target) {
  if (!target) return;
  var noscript = target.getElementsByTagName("noscript")[0];
  if (noscript) {
    var mediaId = noscript.innerHTML.match(/(data-media-id="(\w|-)*")/);
    return mediaId === null || mediaId === void 0 ? void 0 : mediaId[0].replaceAll("data-media-id=\"", "").replaceAll('"', ""); // eslint-disable-line
  }
  return target.getElementsByClassName("video__player")[0] && target.getElementsByClassName("video__player")[0].getAttribute("data-media-id");
}
function disableTransformOfSlideshowItem(article) {
  var slideshowWrapper = article.getElementsByClassName("slideshow__items")[0];
  if (slideshowWrapper) {
    slideshowWrapper.style.transform = null;
  }
}
function shouldBecomeSticky() {
  var _window$Di;
  return ((_window$Di = window.Di) === null || _window$Di === void 0 || (_window$Di = _window$Di.userInfo) === null || _window$Di === void 0 ? void 0 : _window$Di.hasSubscription) === true;
}