import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { formatInstrumentDecimal, formatInstrumentInteger, formatInstrumentPercentage } from "../../helpers/marketDataFormatter";
import { deformatNumber } from "../../helpers/deformat";
import { getCurrentTime } from "../../helpers/dateTimeFormatter";
var LAST_PRICE = "lastprice";
var DIFF_1D = "diff1d";
var DIFF_1D_PRC = "diff1dprc";
var DAY_HIGH_PRICE = "dayhighprice";
var DAY_LOW_PRICE = "daylowprice";
var QUANTITY = "quantity";
var MARKET_CAP = "marketCap";
var SWEDISH_TIME = "swedishTime";
var defaultTableMap = CHANNEL_DESKTOP ? _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, LAST_PRICE, 1), DIFF_1D, 2), DIFF_1D_PRC, 3), DAY_HIGH_PRICE, 4), DAY_LOW_PRICE, 5), QUANTITY, 6), MARKET_CAP, 7), SWEDISH_TIME, 8) : _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, LAST_PRICE, 0), DIFF_1D, 1), DIFF_1D_PRC, 2), DAY_HIGH_PRICE, 3), DAY_LOW_PRICE, 4), QUANTITY, 5), MARKET_CAP, 6), SWEDISH_TIME, 7);
var winnerLosersTableMap = CHANNEL_DESKTOP ? _defineProperty(_defineProperty(_defineProperty({}, DIFF_1D_PRC, 1), LAST_PRICE, 2), SWEDISH_TIME, 3) : _defineProperty(_defineProperty(_defineProperty({}, DIFF_1D_PRC, 0), LAST_PRICE, 1), SWEDISH_TIME, 2);
function buildTableCellMap() {
  var page = window.location.pathname;
  if (page === "/bors/vinnare-forlorare/") {
    return winnerLosersTableMap;
  }
  return defaultTableMap;
}
export default function instrumentTableRealtimeTransform(data) {
  if (!(data !== null && data !== void 0 && data.insref)) return;
  var row = CHANNEL_DESKTOP ? document.getElementById("ins_".concat(data.insref)) : document.getElementById("ins_".concat(data.insref, "--scrollable"));
  if (!row) return;
  var tableCellMap = buildTableCellMap();
  var keys = Object.keys(data).filter(function (key) {
    return (tableCellMap === null || tableCellMap === void 0 ? void 0 : tableCellMap[key]) !== undefined;
  });
  var cells = row.getElementsByTagName("td");
  keys.forEach(function (key) {
    var i = tableCellMap[key];
    var currentCell = cells[i];
    if (currentCell) {
      var newValue = data[key];
      switch (key) {
        case LAST_PRICE:
          triggerAnimation(currentCell, newValue);
          currentCell.innerHTML = "<span>".concat(formatInstrumentDecimal(newValue), "</span>");
          break;
        case DIFF_1D:
          currentCell.innerText = formatInstrumentDecimal(newValue, true);
          formatCell(currentCell, newValue);
          break;
        case DIFF_1D_PRC:
          currentCell.innerText = formatInstrumentPercentage(newValue);
          formatCell(currentCell, newValue);
          break;
        case DAY_HIGH_PRICE:
        case DAY_LOW_PRICE:
          currentCell.innerText = formatInstrumentDecimal(newValue);
          break;
        case QUANTITY:
          currentCell.innerText = formatInstrumentInteger(newValue);
          break;
        case SWEDISH_TIME:
          currentCell.innerText = data.swedishTime ? data.swedishTime.substring(0, 5) : getCurrentTime();
          break;
        default:
          currentCell.innerText = newValue;
          break;
      }
    }
  });
}
function triggerAnimation(currentCell, newValue) {
  var oldCellValue = deformatNumber(currentCell.innerText);
  var change = newValue > oldCellValue ? "positive" : "negative";
  currentCell.classList.add("i-t__c-update", "i-t__c-update--".concat(change));
  window.setTimeout(function () {
    currentCell.classList.remove("i-t__c-update", "i-t__c-update--".concat(change));
  }, 500);
}
function formatCell(currentCell, value) {
  currentCell.classList.remove("i-t__c--p", "i-t__c--n");
  currentCell.classList.add(value >= 0 ? "i-t__c--p" : "i-t__c--n");
}