import scroll from "../helpers/scroll";
var THRESHOLD = 116;
export default function siteHeaderDesktopStart(siteHeaderBig) {
  var siteHeaders = document.getElementsByClassName("js_site-header");
  var siteHeaderSmall = siteHeaders[siteHeaders.length - 1];
  if (!siteHeaderSmall) return;
  var minifiedActivated = false;
  scroll.add(handleScroll);

  // to detect state of initial page load
  isInViewport(siteHeaderSmall);
  function isInViewport(element) {
    var rect = element.getBoundingClientRect();
    if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
      smallHeaderVisible();
    } else {
      largeHeaderVisible();
    }
  }
  function smallHeaderVisible() {
    minifiedActivated = true;
    siteHeaderSmall.classList.add("site-header--in-view");
    siteHeaderBig.classList.remove("site-header-big--in-view");
  }
  function largeHeaderVisible() {
    minifiedActivated = false;
    siteHeaderSmall.classList.remove("site-header--in-view");
    siteHeaderBig.classList.add("site-header-big--in-view");
  }
  function handleScroll(_ref) {
    var scrollY = _ref.scrollY;
    if (!minifiedActivated && scrollY > THRESHOLD) {
      smallHeaderVisible();
    } else if (minifiedActivated && scrollY < THRESHOLD) {
      largeHeaderVisible();
    }
  }
}