export default function fundGameWidgetTracking(pageType) {
  var fundGameBtn = document.getElementsByClassName("js_fund-game-btn")[0];
  if (!fundGameBtn) return;
  fundGameBtn.addEventListener("click", function () {
    window.dataLayer.push({
      event: "Graphics Click",
      eventInfo: {
        label: "Click on button",
        position: pageType || "unknown"
      }
    });
  });
}