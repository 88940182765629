import scriptLoader from "../helpers/scriptLoader";
export default function embed(article) {
  if (!article) return;
  var embeds = article.getElementsByClassName("js-embed");
  if (!embeds.length) return;
  var socialEmbeds = article.getElementsByClassName("js-social-embed");
  if (socialEmbeds !== null && socialEmbeds !== void 0 && socialEmbeds.length) {
    scriptLoader("https://sme.expressen.se/js/social-media-embeds.min.js").then(function () {
      socialMediaEmbeds(article, {}); // eslint-disable-line no-undef
    })["catch"]();
  }
  var iframes = article.getElementsByClassName("js-embed__iframe");
  if (iframes !== null && iframes !== void 0 && iframes.length) {
    var iFrameResize;
    scriptLoader("https://ext.dn.se/qs/widgets/arkku2.0/iframeresizer/iframeResizer.min.js").then(function () {
      iFrameResize = window.iFrameResize;
    }).then(function () {
      for (var i = 0, len = iframes.length; i < len; i++) {
        iFrameResize({
          log: false,
          checkOrigin: false
        }, iframes[i]);
      }
    })["catch"]();
    for (var i = 0; i < iframes.length; i++) {
      var _element$parentNode;
      var element = iframes[i];
      if (!(element !== null && element !== void 0 && element.contentWindow)) continue;
      var _ref = (_element$parentNode = element === null || element === void 0 ? void 0 : element.parentNode) !== null && _element$parentNode !== void 0 ? _element$parentNode : {},
        classList = _ref.classList;
      if (!(classList !== null && classList !== void 0 && classList.contains("embed__pang")) && !(classList !== null && classList !== void 0 && classList.contains("embed__arkku"))) continue;
      initPangWidget(element, i);
    }
  }
}
function initPangWidget(element, index) {
  var VISIBLE_RATIO = 0.6;
  var iframeObj = {
    contentWindow: element.contentWindow,
    index: index,
    element: element,
    intersectionRatio: null
  };
  window.addEventListener("message", function (event) {
    if (!element) return;
    var data = event.data;
    if (data.type === "IFrameLoaded") {
      if (!element.id) {
        element.id = "iframe".concat(Math.floor(Math.random() * 4294967296).toString(16));
      }
      iframeObj.contentWindow.postMessage({
        type: "IFrameInit",
        id: element.id,
        visible: iframeObj.intersectionRatio > VISIBLE_RATIO
      }, "*");
    }
    if (data.type === "IFrameResize") {
      if (!data.height || data.id !== element.id) return;
      var styles = getComputedStyle(element);
      var prev = styles.height;
      var curr = "".concat(data.height, "px");
      if (curr === prev) return;
      element.style.height = curr;
    }
    if (data.type === "IFrameOpenLink" || data.type === "OpenLink") {
      if (!data.url || data.url.includes("://")) return;
      var target = ["_self", "_blank"].includes(data.target) ? data.target : "_self";
      try {
        // open link without query params and skip if invalid url
        var _URL = new URL(data.url, "https://www.di.se"),
          pathname = _URL.pathname;
        window.open(pathname, target);
      } catch (_unused) {
        return;
      }
    }
  });
  var threshold = [VISIBLE_RATIO];
  iframeObj.observer = new window.IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      var _iframeObj$contentWin, _iframeObj$contentWin2, _iframeObj$contentWin3, _iframeObj$contentWin4;
      var intersectionRatio = entry.intersectionRatio;
      iframeObj.intersectionRatio = intersectionRatio;
      (_iframeObj$contentWin = iframeObj.contentWindow) === null || _iframeObj$contentWin === void 0 || (_iframeObj$contentWin2 = _iframeObj$contentWin.postMessage) === null || _iframeObj$contentWin2 === void 0 || _iframeObj$contentWin2.call(_iframeObj$contentWin, {
        type: "intersectionRatio",
        id: element.id,
        intersectionRatio: intersectionRatio
      }, "*");
      (_iframeObj$contentWin3 = iframeObj.contentWindow) === null || _iframeObj$contentWin3 === void 0 || (_iframeObj$contentWin4 = _iframeObj$contentWin3.postMessage) === null || _iframeObj$contentWin4 === void 0 || _iframeObj$contentWin4.call(_iframeObj$contentWin3, {
        type: "IFrameVisible",
        id: element.id,
        visible: intersectionRatio > VISIBLE_RATIO
      }, "*");
    });
  }, {
    rootMargin: "0px",
    threshold: threshold
  });
  iframeObj.observer.observe(element);
}