import initModal from "../modal";
export default function realtimeModal() {
  var modalEl = document.getElementById("realtime-modal");
  if (!modalEl) return;
  modalEl.remove();
  var body = document.getElementsByTagName("body")[0];
  body.appendChild(modalEl);
  var modal = initModal(modalEl);
  var triggerElements = [].slice.call(document.getElementsByClassName("trigger-realtime-modal"));
  triggerElements.forEach(function (element) {
    return element.addEventListener("click", clickHandler);
  });
  var trackElements = document.getElementsByClassName("js_realtime-modal");
  for (var i = 0, len = trackElements.length; i < len; i++) {
    var element = trackElements[i];
    element.addEventListener("click", pushDataLayer);
  }
  function clickHandler(event) {
    event.preventDefault();
    modal.open();
  }
  function pushDataLayer(e) {
    var link = e.target.closest("a");
    if (!link) return;
    window.dataLayer.push({
      event: "Login Click",
      eventInfo: {
        position: link.dataset.position
      }
    });
  }
}