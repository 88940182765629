import setupLazyLoad from "../helpers/setupLazyLoad";
export default function searchShowMoreArticles() {
  var searchArticleHits = document.getElementsByClassName("js_search-article-hits")[0];
  if (!searchArticleHits) return;
  var button = searchArticleHits.getElementsByClassName("js_show-more")[0];
  var intersectionTarget = searchArticleHits.getElementsByClassName("intersection-target")[0];
  if (!button || !intersectionTarget) return;
  button.addEventListener("click", function () {
    return fetchArticles(true);
  });
  var page = 2;
  function fetchArticles(lazyLoad) {
    var query = window.sessionStorage.getItem("searchQuery") || null;
    var sort = window.sessionStorage.getItem("searchSort") || null;
    if (!query || !sort) return;
    var newsItems = document.getElementsByClassName("news-item");
    var lastDay = newsItems.length ? newsItems[newsItems.length - 1].dataset.day : "";
    window.fetch("/search/show-more-articles/?query=".concat(query, "&page=").concat(page, "&sort=").concat(sort, "&lastday=").concat(lastDay)).then(function (response) {
      return response.text();
    }).then(function (articles) {
      page += 1;
      intersectionTarget.insertAdjacentHTML("beforebegin", articles);
      button.remove();
      if (lazyLoad) setupLazyLoad(intersectionTarget, fetchArticles);
    })["catch"](function () {});
  }
}