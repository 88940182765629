export default function jumpToMain() {
  var jumpBtn = document.getElementsByClassName("js_jump-to-main")[0];
  var mainArea = document.getElementsByClassName("js_main-content")[0];
  var firstAnchorLink = mainArea === null || mainArea === void 0 ? void 0 : mainArea.getElementsByTagName("a")[0];
  if (!jumpBtn || !firstAnchorLink) return;
  jumpBtn.addEventListener("keyup", function (_ref) {
    var key = _ref.key;
    if (key === "Enter") firstAnchorLink.focus();
  });
  jumpBtn.addEventListener("click", function () {
    firstAnchorLink.focus();
  });
}