import closeOtherHeaderActions from "../helpers/closeOtherHeaderActions";
import forEach from "../helpers/forEach";
import scroll from "../helpers/scroll";
export default function toggleMegaMenu() {
  var isDesktopStart = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var HEADER_CLASS = isDesktopStart ? "js_site-header-big" : "js_site-header";
  var siteHeader = document.getElementsByClassName(HEADER_CLASS)[0];
  if (!siteHeader) return;
  var megaMenu;
  if (CHANNEL_RESPONSIVE) {
    var megaMenuClass = CHANNEL_MOBILE ? ".js_mega-menu:not(.hidden-mobile)" : CHANNEL_TABLET ? ".js_mega-menu:not(.hidden-tablet)" : CHANNEL_DESKTOP ? ".js_mega-menu:not(.hidden-desktop)" : ".js_mega-menu:not(.hidden-responsive)";
    megaMenu = siteHeader.querySelector(megaMenuClass);
  } else {
    megaMenu = siteHeader.getElementsByClassName("js_mega-menu")[0];
  }
  if (!megaMenu) return;
  var menuToggles = siteHeader.getElementsByClassName("js_menu-toggle");
  if (!(menuToggles !== null && menuToggles !== void 0 && menuToggles.length)) return;
  var overlay = megaMenu.getElementsByClassName("js_mega-menu__overlay")[0];
  forEach(menuToggles, addListener);
  var megaMenuContents = megaMenu.getElementsByClassName("mega-menu__content");
  for (var i = 0, len = megaMenuContents.length; i < len; i++) {
    megaMenuContents[i].addEventListener("click", pushDataLayer);
  }
  function addListener(toggle) {
    toggle.addEventListener("click", function () {
      megaMenu.classList.toggle("hidden");
      if (megaMenu.classList.contains("hidden")) {
        toggle.classList.remove("menu-toggle--active");
        siteHeader.classList.add("site-header--sticky");
        scroll.enableScroll();
      } else {
        scroll.disableScroll();
        toggle.classList.add("menu-toggle--active");
        siteHeader.classList.remove("site-header--sticky");
        if (!CHANNEL_MOBILE) {
          overlay.addEventListener("click", overlayListener);
        }
        closeOtherHeaderActions("menu", siteHeader);
      }
    });
    function overlayListener() {
      toggle.classList.toggle("menu-toggle--active");
      megaMenu.classList.toggle("hidden");
      scroll.enableScroll();
      overlay.removeEventListener("click", overlayListener);
    }
  }
  function pushDataLayer(e) {
    var _e$target$closest;
    var link = e.target.closest("a");
    if (!link) return;
    var megaMenuGroupHeader = (_e$target$closest = e.target.closest(".mega-menu__group")) === null || _e$target$closest === void 0 ? void 0 : _e$target$closest.querySelector(".mega-menu__group-header");
    var isExternalLink = link.getAttribute("target") === "_blank";
    var href = link.getAttribute("href");

    // old
    window.dataLayer.push({
      event: "Menu Click",
      eventInfo: {
        position: "Popout Menu",
        label: link.innerText.trim() || undefined
      }
    });

    // new
    window.dataLayer.push({
      event: "navigation link click",
      eventParams: {
        category: "navigation"
      },
      engagement: {
        type: "click",
        destination: {
          title: link.innerText.trim() || undefined,
          url: isExternalLink ? href : "".concat(window.location.protocol, "//").concat(window.location.host).concat(href),
          type: isExternalLink ? "external" : "internal"
        },
        component: {
          position: "Popout Menu",
          name: "".concat((megaMenuGroupHeader === null || megaMenuGroupHeader === void 0 ? void 0 : megaMenuGroupHeader.innerText.trim()) || undefined, " | ").concat(link.innerText.trim() || undefined),
          type: "hamburger menu"
        }
      }
    });
  }
}