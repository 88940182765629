import throttle from "../../helpers/throttle";
var WRAPPER_CLASS = "instrument-table-scroll";
var START_MODIFIER = "instrument-table-scroll--start";
var MIDDLE_MODIFIER = "instrument-table-scroll--middle";
var END_MODIFIER = "instrument-table-scroll--end";
export default function instrumentTableScroll(scrollArea) {
  var wrapper = scrollArea.parentNode;
  if (!wrapper.classList.contains(WRAPPER_CLASS)) return;
  initScroll(scrollArea, wrapper);
}
function initScroll(scrollArea, wrapper) {
  if (scrollArea.scrollWidth > scrollArea.clientWidth) {
    setWrapperModifier(START_MODIFIER);
  }
  var throttledScrollHandler = throttle(scrollHandler, 50);
  scrollArea.addEventListener("scroll", throttledScrollHandler);
  function scrollHandler() {
    var scrollLeft = scrollArea.scrollLeft,
      scrollWidth = scrollArea.scrollWidth,
      clientWidth = scrollArea.clientWidth;
    if (scrollLeft === 0) {
      setWrapperModifier(START_MODIFIER);
    } else if (scrollWidth === scrollLeft + clientWidth) {
      setWrapperModifier(END_MODIFIER);
    } else {
      setWrapperModifier(MIDDLE_MODIFIER);
    }
  }
  function setWrapperModifier(modifier) {
    if (wrapper.classList.contains(modifier)) return;
    wrapper.classList.remove(START_MODIFIER, END_MODIFIER, MIDDLE_MODIFIER);
    wrapper.classList.add(modifier);
  }
}