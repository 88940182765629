import forEach from "../../helpers/forEach";
import { updateQueryParams } from "../../helpers/updateQueryParams.js";
export default function tabMenu(element) {
  var tabs = element.getElementsByClassName("js_tab-menu__tab");
  var tabTargets = element.getElementsByClassName("js_tab-menu__tab-target");
  var subMenu = element.getElementsByClassName("js_tab-menu__sub-menu")[0];
  var activeTabModifier = element.dataset.toggleClass;
  var showTabMenuStateInAddressBar = element.dataset.showTabMenuStateInAddressBar === "true";
  initializeTabs();
  initializeSubmenu();
  function initializeTabs() {
    var toggleTabs = function toggleTabs(tab) {
      tab.addEventListener("click", function (_ref) {
        var target = _ref.target;
        toggleActiveTab(target);
        toggleTabTarget(target.dataset.target);
        if (target.dataset.target === "sub_menu") showSubmenuTarget();
      });
    };
    forEach(tabs, toggleTabs);
  }
  function initializeSubmenu() {
    if (!subMenu) return;
    subMenu.addEventListener("change", showSubmenuTarget);
  }
  function showSubmenuTarget() {
    if (!subMenu) return;
    var target = subMenu.options[subMenu.selectedIndex].value;
    toggleTabTarget(target);
    subMenu.classList.remove("hidden");
  }
  function toggleActiveTab(activeTab) {
    var remove = function remove(tab, atm) {
      tab.classList.remove(atm);
    };
    forEach(tabs, remove, activeTabModifier);
    activeTab.classList.add(activeTabModifier);
    var tabIndex = getElementIndex("instrument-table-tab-menu__tab--active");
    if (showTabMenuStateInAddressBar) {
      updateQueryParams({
        tab: tabIndex
      }, false);
    }
  }
  function getElementIndex(className) {
    var index = 0;
    var el = document.getElementsByClassName(className)[0];
    while (el && (el = el.previousSibling)) index++;
    return index;
  }
  function toggleTabTarget(tabTarget) {
    var showHide = function showHide(target, tt) {
      if (target.dataset.tab === tt) {
        target.classList.remove("hidden");
      } else {
        target.classList.add("hidden");
      }
    };
    forEach(tabTargets, showHide, tabTarget);
  }
}