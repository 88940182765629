import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export function updateQueryParams(params) {
  var refresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var queryParams = new URLSearchParams(window.location.search);
  Object.entries(params).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    queryParams.set(key, value);
  });
  if (refresh) {
    window.location.search = queryParams.toString();
  } else {
    var newUrl = "".concat(window.location.pathname, "?").concat(queryParams.toString());
    window.history.pushState({
      path: newUrl
    }, "", newUrl);
  }
}