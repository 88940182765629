import forEach from "../helpers/forEach";
export default function loginBtnTracking() {
  if (window.Di.loggedIn) return;
  var loginBtns = document.getElementsByClassName("js_login-btn");
  if (!(loginBtns !== null && loginBtns !== void 0 && loginBtns.length)) return;
  forEach(loginBtns, function (btn) {
    btn.addEventListener("click", pushDataLayer);
  });
}
function pushDataLayer(e) {
  var link = e.target.closest("a");
  if (!link) return;
  window.dataLayer.push({
    event: "Login Click",
    eventInfo: {
      position: link.getAttribute("data-position") || getPageTypeByPath(window.location.pathname)
    }
  });
}
function getPageTypeByPath(path) {
  if (path.includes("/trader/")) {
    return "portfolioPage";
  }
  if (path.includes("/bors/aktier/") || path.includes("/fonder/")) {
    return "instrumentPage";
  }
  switch (path) {
    case "/bevakningar/":
      return "watchlistPage";
    case "/digital/":
      return "sectionPage";
    case "/nyhetsbrev/":
      return "newsletterPage";
    case "/amnen/hallbart-naringsliv/":
    case "/amnen/jamstallt-naringsliv/":
    case "/amnen/mobilitet/":
    case "/amnen/tech-och-strategi/":
      return "verticalPage";
    default:
      return null;
  }
}