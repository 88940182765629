export default function socialMediaModal() {
  var triggerElements = document.getElementsByClassName("js_social-modal");
  for (var i = 0, len = triggerElements.length; i < len; i++) {
    var element = triggerElements[i];
    element.addEventListener("click", pushDataLayer);
  }
  function pushDataLayer(e) {
    var link = e.target.closest("a");
    if (!link) return;
    window.dataLayer.push({
      event: "Social Event",
      eventInfo: {
        network: link.getAttribute("data-position") || undefined,
        action: "Share",
        target: link.getAttribute("href") || undefined
      }
    });
  }
}