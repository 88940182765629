import capitalize from "../helpers/capitalize";
export default function siteHeaderMenu() {
  var triggerElements = document.getElementsByClassName("js_header-links");
  for (var i = 0, len = triggerElements.length; i < len; i++) {
    var element = triggerElements[i];
    element.addEventListener("click", pushDataLayer);
  }
  function pushDataLayer(e) {
    var _e$target$getElements;
    var link = (_e$target$getElements = e.target.getElementsByTagName("span")[0]) !== null && _e$target$getElements !== void 0 ? _e$target$getElements : e.target.closest("a");
    if (!link) return;
    var isExternalLink = link.getAttribute("target") === "_blank";
    var href = link.getAttribute("href");

    //old
    window.dataLayer.push({
      event: "Menu Click",
      eventInfo: {
        position: "Top Menu",
        label: capitalize(link.innerText)
      }
    });

    // new
    window.dataLayer.push({
      event: "navigation link click",
      eventParams: {
        category: "navigation"
      },
      engagement: {
        type: "click",
        destination: {
          title: link.innerText.trim() || undefined,
          url: isExternalLink ? href : "".concat(window.location.protocol, "//").concat(window.location.host).concat(href),
          type: isExternalLink ? "external" : "internal"
        },
        component: {
          position: "Top Menu",
          name: "".concat(link.innerText.trim() || undefined),
          type: "top menu"
        }
      }
    });
  }
}