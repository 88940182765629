export function markPreviousItem(searchItems, baseClassName) {
  if (searchItems.length <= 1) return;
  for (var i = 1, len = searchItems.length; i < len; i++) {
    var item = searchItems[i];
    if (item.className.indexOf("--marked") >= 0) {
      item.classList.remove("".concat(baseClassName, "--marked"));
      searchItems[i - 1].classList.add("".concat(baseClassName, "--marked"));
      focusItemLink(searchItems[i - 1]);
      break;
    }
  }
}
export function markNextItem(context, searchItems, baseClassName) {
  var searchItemsMarked = context.getElementsByClassName("".concat(baseClassName, "--marked"));
  if (!(searchItemsMarked !== null && searchItemsMarked !== void 0 && searchItemsMarked.length)) {
    if (!searchItems[0]) return;
    searchItems[0].classList.add("".concat(baseClassName, "--marked"));
    return;
  }
  for (var i = 0, len = searchItems.length; i < len - 1; i++) {
    var item = searchItems[i];
    if (item.className.indexOf("--marked") >= 0) {
      item.classList.remove("".concat(baseClassName, "--marked"));
      searchItems[i + 1].classList.add("".concat(baseClassName, "--marked"));
      focusItemLink(searchItems[i + 1]);
      break;
    }
  }
}
function focusItemLink(item) {
  var anchorElement = Array.from((item === null || item === void 0 ? void 0 : item.children) || []).find(function (e) {
    return e.tagName === "A";
  });
  anchorElement === null || anchorElement === void 0 || anchorElement.focus();
}