import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { formatInstrumentDecimal, formatInstrumentPercentage, formatLastPrice } from "../../helpers/marketDataFormatter";
var splittedArr = window.location.href.split("/");
var url = splittedArr[splittedArr.length - 2];
var insref = parseInt(url.substring(url.lastIndexOf("-") + 1));
var LAST_PRICE = "lastprice";
var DIFF_1D = "diff1d";
var DIFF_1D_PRC = "diff1dprc";
var cellMap = CHANNEL_DESKTOP ? _defineProperty(_defineProperty(_defineProperty({}, LAST_PRICE, 1), DIFF_1D, 2), DIFF_1D_PRC, 3) : _defineProperty(_defineProperty(_defineProperty({}, LAST_PRICE, 0), DIFF_1D, 1), DIFF_1D_PRC, 2);
export default function stockPageRealtimeTransform(socketData) {
  if (socketData.insref !== insref) return;
  var instrumentDetails = document.getElementsByClassName("js_instrument-details")[0];
  var lastPriceCell = instrumentDetails.getElementsByClassName("js_instrument-details__price")[0];
  var diffPriceCell = instrumentDetails.getElementsByClassName("js_instrument-details__diff")[0];
  var diffPrcCell = instrumentDetails.getElementsByClassName("js_instrument-details__diffprc")[0];
  var timeCell = instrumentDetails.getElementsByClassName("js_instrument-details__time")[0];
  if (!lastPriceCell || !diffPriceCell || !diffPrcCell || !timeCell) return;
  var keys = Object.keys(socketData).filter(function (key) {
    return cellMap[key] !== undefined;
  });
  keys.forEach(function (key) {
    var newValue = socketData[key];
    switch (key) {
      case LAST_PRICE:
        updateClass(lastPriceCell, "instrument-details__realtime-lastprice", lastPriceCell.innerText, newValue);
        lastPriceCell.innerText = formatLastPrice(newValue);
        updateTime(timeCell, socketData.swedishTime);
        break;
      case DIFF_1D:
        diffPriceCell.innerText = formatInstrumentDecimal(newValue, true);
        updateTime(timeCell, socketData.swedishTime);
        break;
      case DIFF_1D_PRC:
        diffPrcCell.innerText = formatInstrumentPercentage(newValue);
        updateTime(timeCell, socketData.swedishTime);
    }
  });
  return socketData;
}
function updateTime(timeCell, newTime) {
  if (!newTime) return;
  timeCell.innerText = "".concat(newTime.substring(0, newTime.lastIndexOf(":")));
}
function updateClass(element, baseClass, oldValue, newValue) {
  if (oldValue === newValue) return;
  var realtimeClass = "".concat(baseClass, "--").concat(newValue > parseFloat(oldValue.replace(",", ".")) ? "positive" : "negative");
  element.classList.add(realtimeClass);
  window.setTimeout(function () {
    return element.classList.remove(realtimeClass);
  }, 200);
}