function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import dataLayerPush from "../helpers/dataLayerPush";
export default function theoPlayer(scope, origin) {
  ensureResourcesAreLoaded(scope);
  window.playerManager = window.playerManager || [];
  var isArticle = document.getElementsByClassName("article__wrapper").length > 0;
  var isTvArticlePage = window.location.pathname.match(/(ditv\/\w)/);
  var intersectionThreshold = 0.1;
  var observer = new window.IntersectionObserver(viewPortUpdate, {
    threshold: intersectionThreshold
  });
  var videos = scope.getElementsByClassName("video");
  if (origin === "mainDocument") {
    window.playerManager.push(function (manager) {
      var options = {
        autoplayNext: true,
        //will only play maximum 5 videos, won't play unlimited amount of videos as before
        className: "evp",
        cmpEnabled: hasCMP(),
        external: true,
        partnerId: "di"
      };
      if (isArticle) {
        options.canShrinkInDimensions = true;
      }
      if (isTvArticlePage) {
        options.autoplayWithSound = true;
      }
      manager.init(options);
    });
    var _iterator = _createForOfIteratorHelper(videos),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var vid = _step.value;
        videoTracking(vid);
        observer.observe(vid);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  } else {
    window.playerManager.push(function (manager) {
      manager.update();
    });
    if (origin === "lazyLoad") {
      var _iterator2 = _createForOfIteratorHelper(videos),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _vid = _step2.value;
          observer.observe(_vid);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }
  function viewPortUpdate(entries) {
    entries.forEach(function (_ref) {
      var target = _ref.target,
        intersectionRatio = _ref.intersectionRatio;
      if (isArticle && CHANNEL_MOBILE) return;
      if (intersectionRatio > intersectionThreshold) {
        target.setAttribute("data-visible", true);
      } else {
        target.removeAttribute("data-visible");
      }
    });
  }
}
function ensureResourcesAreLoaded(scope) {
  var _window$playerManger;
  if ((_window$playerManger = window.playerManger) !== null && _window$playerManger !== void 0 && _window$playerManger.length || !scope.getElementsByClassName("video__player").length) return;
  var resources = document.getElementsByClassName("video-player-resource");
  if (resources !== null && resources !== void 0 && resources.length) {
    for (var i = 0; i < resources.length; ++i) {
      var _resource$dataset, _resource$dataset2;
      var resource = resources[i];
      if ((_resource$dataset = resource.dataset) !== null && _resource$dataset !== void 0 && _resource$dataset.src) {
        resource.src = resource.dataset.src;
      } else if ((_resource$dataset2 = resource.dataset) !== null && _resource$dataset2 !== void 0 && _resource$dataset2.href) {
        resource.href = resource.dataset.href;
      }
    }
  }
}
function hasCMP() {
  return !!document.getElementById("cmp-script");
}
function videoTracking(video) {
  var _window$kraken;
  if (!video) return;
  window.kraken = (_window$kraken = window.kraken) !== null && _window$kraken !== void 0 ? _window$kraken : {};
  window.kraken.diTracking = function (trackingData, videoElement) {
    var position = videoElement.getAttribute("data-position");
    var visible = videoElement.getAttribute("data-visible") === "true";
    //old
    dataLayerPush({
      event: "Video Playback",
      videoInfo: {
        position: position,
        videoVisible: visible,
        player: "Theo Player"
      },
      eventInfo: trackingData
    });
  };
}