import scroll from "./scroll";
export default function closeOtherHeaderActions(currentAction, siteHeader) {
  if (!currentAction || !siteHeader) return;
  var userMenu = siteHeader.getElementsByClassName("js_user-menu")[0];
  var searchForm = siteHeader.getElementsByClassName("js_search-form")[0];
  var megaMenu = siteHeader.getElementsByClassName("js_mega-menu")[0];
  if (currentAction !== "user" && !(userMenu !== null && userMenu !== void 0 && userMenu.classList.contains("hidden"))) {
    userMenu === null || userMenu === void 0 || userMenu.classList.add("hidden");
    var siteOverlay = document.getElementsByClassName("js_site-overlay")[0];
    siteOverlay === null || siteOverlay === void 0 || siteOverlay.classList.add("hidden");
  }
  if (currentAction !== "search" && !(searchForm !== null && searchForm !== void 0 && searchForm.classList.contains("hidden"))) {
    searchForm === null || searchForm === void 0 || searchForm.classList.add("hidden");
    var searchToggle = siteHeader.getElementsByClassName("js_search-toggle")[0];
    searchToggle === null || searchToggle === void 0 || searchToggle.classList.remove("search-toggle--active");
    var searchOverlay = siteHeader.getElementsByClassName("search-form__overlay")[0];
    searchOverlay === null || searchOverlay === void 0 || searchOverlay.classList.add("hidden");
    if (currentAction !== "menu") scroll.enableScroll();
  }
  if (currentAction !== "menu" && !(megaMenu !== null && megaMenu !== void 0 && megaMenu.classList.contains("hidden"))) {
    megaMenu === null || megaMenu === void 0 || megaMenu.classList.add("hidden");
    var menuToggle = siteHeader.getElementsByClassName("js_menu-toggle")[0];
    menuToggle === null || menuToggle === void 0 || menuToggle.classList.remove("menu-toggle--active");
    if (currentAction !== "search") scroll.enableScroll();
  }
}