export default function loginPopup() {
  var _window$sessionStorag;
  var loginPopupShown = (_window$sessionStorag = window.sessionStorage) === null || _window$sessionStorag === void 0 ? void 0 : _window$sessionStorag.getItem("loginPopupShown");
  if (loginPopupShown === "true") return;
  var popup = document.getElementsByClassName("js_login-popup")[0];
  if (!popup) return;
  popup.classList.remove("hidden");
  if (window.sessionStorage) {
    window.sessionStorage.setItem("loginPopupShown", "true");
  }
  window.setTimeout(function () {
    popup.classList.add("hidden");
  }, 5000);
  var popupCloseButton = popup.getElementsByClassName("login-popup__close")[0];
  if (!popupCloseButton) return;
  var close = function close() {
    popup.classList.add("hidden");
  };
  popupCloseButton.addEventListener("click", close);
}