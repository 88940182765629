export default function instrumentTableStatic(wrapper) {
  if (!wrapper) return;
  var fixedTable = wrapper.getElementsByClassName("js_instrument-table__fixed-part")[0];
  var scrollableTable = wrapper.getElementsByClassName("js_instrument-table-scroll__scrollable-part")[0];
  if (!fixedTable || !scrollableTable) return;
  initHover(fixedTable, scrollableTable);
}
function initHover(fixedTable, scrollableTable) {
  var fixedTableRows = fixedTable.getElementsByClassName("static-table__row");
  var scrollableTableRows = scrollableTable.getElementsByClassName("static-table__row");
  var _loop = function _loop() {
    var fixedRow = fixedTableRows[i];
    var scrollableRow = scrollableTableRows[i];
    fixedRow.addEventListener("mouseover", function () {
      fixedRow.classList.add("static-table__row--hover");
      scrollableRow.classList.add("static-table__row--hover");
    });
    fixedRow.addEventListener("mouseleave", function () {
      fixedRow.classList.remove("static-table__row--hover");
      scrollableRow.classList.remove("static-table__row--hover");
    });
    scrollableRow.addEventListener("mouseover", function () {
      fixedRow.classList.add("static-table__row--hover");
      scrollableRow.classList.add("static-table__row--hover");
    });
    scrollableRow.addEventListener("mouseleave", function () {
      fixedRow.classList.remove("static-table__row--hover");
      scrollableRow.classList.remove("static-table__row--hover");
    });
  };
  for (var i = 0; i < fixedTableRows.length; i++) {
    _loop();
  }
}