var _window$pageXOffset, _window, _window$pageYOffset, _window2;
import passiveIfPossible from "./passiveIfPossible";
import throttle from "./throttle";
export default {
  add: addScrollListener,
  remove: removeScrollListener,
  enableScroll: enableScroll,
  disableScroll: disableScroll
};
var scrollListeners = [];
var throttledScroll;
var scrollBehaviorSupported;
var listeningToScroll = false;
var wnd = window;
var lastScrollX = (_window$pageXOffset = (_window = window) === null || _window === void 0 ? void 0 : _window.pageXOffset) !== null && _window$pageXOffset !== void 0 ? _window$pageXOffset : 0;
var lastScrollY = (_window$pageYOffset = (_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.pageYOffset) !== null && _window$pageYOffset !== void 0 ? _window$pageYOffset : 0;
function scrollListenerExists(fn) {
  return scrollListeners.indexOf(fn) > -1;
}
function scroll() {
  var _window$pageXOffset2, _window3, _window$pageYOffset2, _window4;
  var scrollX = (_window$pageXOffset2 = (_window3 = window) === null || _window3 === void 0 ? void 0 : _window3.pageXOffset) !== null && _window$pageXOffset2 !== void 0 ? _window$pageXOffset2 : 0;
  var scrollY = (_window$pageYOffset2 = (_window4 = window) === null || _window4 === void 0 ? void 0 : _window4.pageYOffset) !== null && _window$pageYOffset2 !== void 0 ? _window$pageYOffset2 : 0;
  var currentListeners = scrollListeners.slice();
  for (var i = 0, len = currentListeners.length; i < len; ++i) {
    currentListeners[i]({
      scrollX: scrollX,
      lastScrollX: lastScrollX,
      scrollY: scrollY,
      lastScrollY: lastScrollY
    });
  }
  lastScrollX = scrollX;
  lastScrollY = scrollY;
}
function addScrollListener(fn, immediate) {
  if (window !== wnd) {
    scrollListeners.length = 0; //empty array
    listeningToScroll = false;
    wnd = window;
  }
  if (scrollListenerExists(fn)) return;
  scrollListeners.push(fn);
  lastScrollX = window.pageXOffset;
  lastScrollY = window.pageYOffset;
  if (immediate) {
    fn({
      scrollX: lastScrollX,
      lastScrollX: lastScrollX,
      scrollY: lastScrollY,
      lastScrollY: lastScrollY
    });
  }
  if (listeningToScroll) return;
  listeningToScroll = true;
  throttledScroll = throttle(scroll, 70);
  window.addEventListener("scroll", throttledScroll, passiveIfPossible());
}
function removeScrollListener(fn) {
  var index = scrollListeners.indexOf(fn);
  if (index > -1) {
    scrollListeners.splice(index, 1);
  }
  if (scrollListeners.length) return;
  window.removeEventListener("scroll", throttledScroll);
  listeningToScroll = false;
}
function disableScroll() {
  if (isScrollBehaviorSupported()) {
    document.documentElement.style.scrollBehavior = "unset";
  }
  document.documentElement.classList.add("disable-scroll");
}
function enableScroll() {
  document.documentElement.classList.remove("disable-scroll");
  if (isScrollBehaviorSupported()) {
    document.documentElement.style.scrollBehavior = "";
  }
}
function isScrollBehaviorSupported() {
  if (scrollBehaviorSupported === undefined) {
    scrollBehaviorSupported = "scrollBehavior" in document.documentElement.style;
  }
  return scrollBehaviorSupported;
}