function addError(formInput, errorMessage) {
  formInput.classList.add("form-input__error");
  var error = formInput.getElementsByClassName("js_form-input-wrapper__error-message")[0];
  error.innerText = errorMessage;
  error.classList.remove("hidden");
}
function clearError(formInput) {
  formInput.classList.remove("form-input__error");
  var error = formInput.getElementsByClassName("js_form-input-wrapper__error-message")[0];
  if (error) {
    error.innerText = "";
    error.classList.add("hidden");
  }
}
export { addError, clearError };