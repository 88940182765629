import forEach from "../helpers/forEach";
import initModal from "./modal";
export default function loginModal() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  if (window.Di.loggedIn) return;
  var modalEl = document.getElementById("js_login-modal");
  if (!modalEl) return;
  var modal = initModal(modalEl);
  var triggerElements = context.getElementsByClassName("trigger-login-modal");
  forEach(triggerElements, function (trigger) {
    trigger.addEventListener("click", clickHandler);
  });
  function clickHandler(event) {
    event.preventDefault();
    modal.open();
  }
}