import scrollEffects from "./scrollEffects";
export default function graphicBanner() {
  var graphicBannerEl = document.getElementsByClassName("js_graphic-banner")[0];
  if (!graphicBannerEl) return;
  scrollEffects(graphicBannerEl, "graphic-banner__wrapper");
  var reportingInsts = graphicBannerEl.getElementsByClassName("graphic-banner__inst");
  if (!(reportingInsts !== null && reportingInsts !== void 0 && reportingInsts.length)) return;
  var leftBtn = graphicBannerEl.getElementsByClassName("js_graphic-banner__btn-left")[0];
  leftBtn === null || leftBtn === void 0 || leftBtn.addEventListener("click", function () {
    return scrollBanner(graphicBannerEl, true);
  });
  var rightBtn = graphicBannerEl.getElementsByClassName("js_graphic-banner__btn-right")[0];
  rightBtn === null || rightBtn === void 0 || rightBtn.addEventListener("click", function () {
    return scrollBanner(graphicBannerEl);
  });
}
var left = 0;
function scrollBanner(banner, isDirectionLeft) {
  var scrollValue = CHANNEL_MOBILE ? 230 : CHANNEL_TABLET ? window.visualViewport.width - 130 : 1000;
  left = isDirectionLeft ? left - scrollValue : left + scrollValue;
  banner.scrollTo({
    left: left,
    behavior: "smooth"
  });
}