import throttle from "../helpers/throttle";
export default function scrollEffects(scrollArea) {
  var wrapperClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "market-navigation-scroll-wrapper";
  if (!scrollArea) return;
  var wrapper = scrollArea.parentNode;
  if (!wrapper.classList.contains(wrapperClass)) return;
  initScroll(scrollArea, wrapper, wrapperClass);
}
function initScroll(scrollArea, wrapper, wrapperClass) {
  var startModifier = "".concat(wrapperClass, "--start");
  var middleModifier = "".concat(wrapperClass, "--middle");
  var endModifier = "".concat(wrapperClass, "--end");
  if (scrollArea.scrollWidth > scrollArea.clientWidth) {
    setWrapperModifier(startModifier);
  }
  var throttledScrollHandler = throttle(scrollHandler, 50);
  scrollArea.addEventListener("scroll", throttledScrollHandler);
  function scrollHandler() {
    var scrollLeft = scrollArea.scrollLeft,
      scrollWidth = scrollArea.scrollWidth,
      clientWidth = scrollArea.clientWidth;
    if (scrollLeft === 0) {
      setWrapperModifier(startModifier);
    } else if (scrollWidth === scrollLeft + clientWidth) {
      setWrapperModifier(endModifier);
    } else {
      setWrapperModifier(middleModifier);
    }
  }
  function setWrapperModifier(modifier) {
    if (wrapper.classList.contains(modifier)) return;
    wrapper.classList.remove(startModifier, endModifier, middleModifier);
    wrapper.classList.add(modifier);
  }
}