import scroll from "../helpers/scroll";
var CONTEXT_TOP_STICKY = 50;
var STATE = {
  NONE: 0,
  BOTTOM_ALIGNED: 1,
  FIXED: 2
};
export default function outsiderAds() {
  var outsiders = document.getElementsByClassName("outsider-ad");
  if (!outsiders.length) return;
  var lastIndex = outsiders.length - 1;
  var outsider2 = {
    ad: outsiders[lastIndex].firstElementChild,
    ctx: outsiders[lastIndex],
    state: STATE.NONE
  };
  if (supportsSticky()) return;
  for (var i = 0, len = outsiders.length; i < len; i++) {
    transformToFallback(outsiders[i]);
  }
  outsiders[0].classList.add("outsider-ad-fallback--first");
  scroll.add(checkOutsiders, true);
  function checkOutsiders() {
    outsider2.top = outsider2.ctx.getBoundingClientRect().top;
    setState(outsider2, getOutsider2State());
  }
  function getOutsider2State() {
    if (outsider2.top <= CONTEXT_TOP_STICKY) {
      return STATE.FIXED;
    }
    return STATE.NONE;
  }
  function setState(outsider, state) {
    if (outsider.state === state) return;
    switch (state) {
      case STATE.NONE:
        outsider.ad.classList.remove("outsider-ad__fixed");
        outsider.ctx.classList.remove("outsider-ad-fallback--bottom-aligned");
        break;
      case STATE.FIXED:
        outsider.ad.classList.add("outsider-ad__fixed");
        outsider.ctx.classList.remove("outsider-ad-fallback--bottom-aligned");
        break;
      case STATE.BOTTOM_ALIGNED:
        outsider.ad.classList.remove("outsider-ad__fixed");
        outsider.ctx.classList.add("outsider-ad-fallback--bottom-aligned");
        break;
    }
    outsider.state = state;
  }
}
function supportsSticky() {
  var testNode = document.createElement("div");
  return ["", "-webkit-", "-moz-", "-ms-"].some(function (prefix) {
    var _testNode$style;
    try {
      testNode.style.position = "".concat(prefix, "sticky");
    } catch (_) {
      // ignore
    }
    return !!(testNode !== null && testNode !== void 0 && (_testNode$style = testNode.style) !== null && _testNode$style !== void 0 && _testNode$style.position);
  });
}
function transformToFallback(context) {
  context.firstElementChild.classList.remove("outsider-ad__sticky");
  context.classList.remove("outsider-ad--first", "outsider-ad--second");
  context.classList.add("outsider-ad-fallback");
}