import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export default function showToaster() {
  var _Object$keys;
  var boldedText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Något gick fel";
  var cause = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var additionalEl = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var siteHeader = document.getElementsByClassName("js_site-header")[0];
  if (!siteHeader) return;
  var toaster = document.createElement("article");
  toaster.setAttribute("class", "toaster");
  var info = document.createElement("p");
  info.classList.add("toaster__text");
  if (cause) info.textContent = "".concat(cause);
  var messageElStr = document.createElement("b");
  messageElStr.textContent = boldedText;
  info.insertAdjacentElement("afterbegin", messageElStr);
  var addedElStr = "";
  if ((_Object$keys = Object.keys(additionalEl)) !== null && _Object$keys !== void 0 && _Object$keys.length) {
    var _Object$keys2;
    var htmlEl = additionalEl.htmlEl,
      text = additionalEl.text,
      attributes = additionalEl.attributes;
    addedElStr = document.createElement(htmlEl);
    if ((_Object$keys2 = Object.keys(attributes)) !== null && _Object$keys2 !== void 0 && _Object$keys2.length) {
      for (var _i = 0, _Object$entries = Object.entries(attributes); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];
        addedElStr.setAttribute(key, value);
      }
    }
    addedElStr.textContent = text;
    info.insertAdjacentElement("beforeend", addedElStr);
  }
  var figure = document.createElement("figure");
  figure.classList.add("toaster__close");
  figure.innerHTML = "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' class='icon icon--feather-close icon-feather'><use xlink:href=#feather-close></use></svg>";
  figure.addEventListener("click", function () {
    return toaster.remove();
  });
  toaster.appendChild(info);
  toaster.appendChild(figure);
  siteHeader.appendChild(toaster);
  if (window.Di.environment !== "test") {
    window.setTimeout(function () {
      toaster.classList.add("toaster--fade-out");
      window.setTimeout(function () {
        return toaster.remove();
      }, 500);
    }, 5000);
  }
}