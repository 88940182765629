import getChannel from "../helpers/getChannel";
var metrics, wnd;
export default function init() {
  if (!window.webVitals || !window.webVitals.onCLS) {
    var script = document.getElementById("web-vitals-script");
    return script && script.addEventListener("load", init);
  }
  window.webVitals.onCLS(register);
  window.webVitals.onFCP(register);
  window.webVitals.onINP(register);
  window.webVitals.onLCP(register);
  window.webVitals.onTTFB(register);

  // Report all available metrics whenever the page is backgrounded or unloaded.
  window.addEventListener("visibilitychange", track);

  // NOTE: Safari does not reliably fire the `visibilitychange` event when the
  // page is being unloaded. If Safari support is needed, you should also flush
  // the queue in the `pagehide` event.
  window.addEventListener("pagehide", track);
}
function register(_ref) {
  var name = _ref.name,
    value = _ref.value,
    rating = _ref.rating;
  if (wnd !== window) {
    wnd = window;
    metrics = null;
  }
  metrics = metrics || {};
  metrics[name] = {
    name: name,
    value: value,
    rating: rating
  };
}
function track(event) {
  if (!metrics) return;
  if (event.type === "visibilitychange" && document.visibilityState !== "hidden") return;
  window.removeEventListener("visibilitychange", track);
  window.removeEventListener("pagehide", track);
  if (CHANNEL_MOBILE && window.Di.config.rumReporterUrl) {
    window.navigator.sendBeacon(window.Di.config.rumReporterUrl, JSON.stringify({
      metrics: Object.values(metrics),
      deviceCategory: getChannel(),
      platformName: "flamingo",
      pageType: window.Di.gtmData.content.type.toLowerCase()
    }));
  }
}