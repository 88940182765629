import panoramaScrollAway from "./panoramaScrollAway";
import scroll from "../helpers/scroll";
var siteHeaderElm, siteHeaderClosed, isFullpageAdShown, fullpageAdHeight;
if (isFullpageAdShown) {
  var fullpageAdHeader = document.getElementsByClassName("ad-fullpage__header")[0];
  if (fullpageAdHeader) {
    fullpageAdHeader.addEventListener("click", function () {
      fullpageAdHeight = 0;
      isFullpageAdShown = false;
    });
  }
}
export default function siteHeader() {
  siteHeaderClosed = false;
  isFullpageAdShown = hasFullpageAd();
  fullpageAdHeight = isFullpageAdShown ? window.innerHeight : 0;
  var siteHeaderBig = document.getElementsByClassName("js_site-header-big")[0];
  if (siteHeaderBig) {
    import("./siteHeaderDesktopStart").then(function (m) {
      return m["default"](siteHeaderBig);
    });
    import("./panoramaScrollAway").then(function (m) {
      return m["default"]();
    });
    return;
  }
  siteHeaderElm = document.getElementsByClassName("site-header")[0];
  if (!siteHeaderElm || isSearchFormVisible()) return;
  if (CHANNEL_MOBILE) scroll.add(scrollSiteHeader);
  if (CHANNEL_TABLET || CHANNEL_DESKTOP) panoramaScrollAway();
}
function scrollSiteHeader(_ref) {
  var scrollY = _ref.scrollY,
    lastScrollY = _ref.lastScrollY;
  if (isFullpageAdShown) {
    if (scrollY > fullpageAdHeight) {
      siteHeaderScrollMobile({
        scrollY: scrollY,
        lastScrollY: lastScrollY
      });
    }
  } else {
    siteHeaderScrollMobile({
      scrollY: scrollY,
      lastScrollY: lastScrollY
    });
  }
}
var siteHeaderHeight = 119;
function siteHeaderScrollMobile(_ref2) {
  var scrollY = _ref2.scrollY,
    lastScrollY = _ref2.lastScrollY;
  var threshold = 10;
  if (!siteHeaderClosed && scrollY > lastScrollY) {
    siteHeaderElm.classList.remove("site-header--sticky");
    if (scrollY >= siteHeaderHeight) {
      siteHeaderElm.classList.add("site-header--up");
      siteHeaderClosed = true;
    }
  } else if (siteHeaderClosed && lastScrollY - scrollY >= threshold) {
    siteHeaderElm.classList.remove("site-header--up");
    siteHeaderElm.classList.add("site-header--sticky");
    siteHeaderClosed = false;
  }
}
function isSearchFormVisible() {
  var searchForm = siteHeaderElm.getElementsByClassName("js_search-form")[0];
  return searchForm && !searchForm.classList.contains("hidden");
}
function hasFullpageAd() {
  return !!document.getElementsByClassName("ad-fullpage")[0];
}