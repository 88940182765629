import dataLayerPush from "../helpers/dataLayerPush";
import forEach from "../helpers/forEach";
export default function articleGallery() {
  var articleGalleryEl = document.getElementsByClassName("js_article-gallery")[0];
  if (!articleGalleryEl) return;
  var articleItems = articleGalleryEl.getElementsByClassName("js_article-gallery-item");
  if (!(articleItems !== null && articleItems !== void 0 && articleItems.length)) return;
  var addClickEvent = function addClickEvent(article) {
    article.addEventListener("click", function (e) {
      return pushDataLayer(e, articleGalleryEl);
    });
  };
  forEach(articleItems, addClickEvent);
}
function pushDataLayer(e, gallery) {
  var _e$target;
  var link = (_e$target = e.target) === null || _e$target === void 0 || (_e$target = _e$target.closest("a")) === null || _e$target === void 0 ? void 0 : _e$target.getAttribute("href");
  if (!link) return;
  dataLayerPush({
    event: "Graphics Click",
    eventInfo: {
      label: "Click on link ".concat(gallery.dataset.type, " Gallery"),
      position: link
    }
  });
}