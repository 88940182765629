import initModal from "./modal";
export default function loginModal() {
  var _window$Di;
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  if ((_window$Di = window.Di) !== null && _window$Di !== void 0 && _window$Di.hasAccessToken) return;
  var modal;
  var activeElements = [];
  if (!modal) {
    var modalEl = context.getElementsByClassName("modal--subscribe")[0];
    modal = initModal(modalEl);
  }
  var triggerElements = context.getElementsByClassName("trigger-subscribe-modal");
  for (var i = 0, len = triggerElements.length; i < len; i++) {
    var element = triggerElements[i];
    if (activeElements.includes(element)) return;
    element.addEventListener("click", clickHandler);
    activeElements.push(element);
  }
  function clickHandler(event) {
    event.preventDefault();
    modal.open();
  }
}