export { formatInstrumentDecimal, formatInstrumentInteger, formatInstrumentPercentage, formatLastPrice, indicatorClass };
function formatInstrumentDecimal(value) {
  var signed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var decimals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  if (isInvalidValue(value)) return "-";
  var fixedNumber = parseFloat(value).toFixed(decimals);
  var styledNumber = "".concat(fixedNumber).replace(/[.]/, ",").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return signed && fixedNumber > 0 ? "+".concat(styledNumber) : styledNumber;
}
function formatInstrumentInteger(value, divider) {
  if (isInvalidValue(value)) return "-";
  var intNumber = divider ? parseInt(parseInt(value) / divider) : parseInt(value);
  return intNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function formatInstrumentPercentage(value) {
  var signed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (isInvalidValue(value)) return "-";
  return "".concat(formatInstrumentDecimal(value, signed), "%");
}
function formatLastPrice(value) {
  if (isInvalidValue(value)) return "-";
  if (value < 1000) return formatInstrumentDecimal(value);
  if (value < 10000) return formatInstrumentDecimal(value, false, 1);
  return formatInstrumentDecimal(value, false, 0);
}
function indicatorClass(value) {
  var baseClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "portfolio-details__number-value";
  if (parseFloat(value) === 0 || !value || isNaN(value)) {
    return "";
  }
  return value > 0 ? "".concat(baseClass, "--positive") : "".concat(baseClass, "--negative");
}
function isInvalidValue(value) {
  return !value && value !== 0 || isNaN(value);
}