import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var panorama, siteWrapper, siteBody, stickyOutsider, nativeBanner, articleMain, nativeArticle;
var TABLET_TICKER_HEIGHT = 30;
var TABLET_HEADER_HEIGHT = 88;
var TABLET_PANORAMA_HEIGHT = 196;
var DESKTOP_TICKER_HEIGHT = 32;
var DESKTOP_HEADER_HEIGHT = 48;
var DESKTOP_PANORAMA_HEIGHT = 256;
var BASE_GUTTER = 16;
var startPosition, lastPosition, scrollOutStartPosition, panoramaTopPosition, panoramaHeight, lockBreakPoint, isPanoramaScrollingOut, isStartPage;
export default function panoramaScrollAway() {
  var _siteWrapper, _siteBody, _siteBody2, _siteBody3, _siteBody4;
  panorama = document.getElementsByClassName("js_site-header__panorama")[0];
  siteWrapper = document.getElementsByClassName("js_site-wrapper")[0];
  siteBody = (_siteWrapper = siteWrapper) === null || _siteWrapper === void 0 ? void 0 : _siteWrapper.getElementsByClassName("site-body")[0];
  stickyOutsider = (_siteBody = siteBody) === null || _siteBody === void 0 ? void 0 : _siteBody.getElementsByClassName("outsider-ad__sticky")[0];
  nativeBanner = (_siteBody2 = siteBody) === null || _siteBody2 === void 0 ? void 0 : _siteBody2.getElementsByClassName("native-article__banner")[0];
  articleMain = (_siteBody3 = siteBody) === null || _siteBody3 === void 0 ? void 0 : _siteBody3.getElementsByClassName("article__main")[0];
  nativeArticle = (_siteBody4 = siteBody) === null || _siteBody4 === void 0 ? void 0 : _siteBody4.getElementsByClassName("native-article")[0];
  isPanoramaScrollingOut = false;
  if (!panorama || !siteWrapper || !siteBody) return;
  isStartPage = !!siteBody.classList.contains("site-body--start-page");
  var _ref = CHANNEL_TABLET ? [0, TABLET_PANORAMA_HEIGHT, TABLET_TICKER_HEIGHT + TABLET_HEADER_HEIGHT] : [DESKTOP_HEADER_HEIGHT + (isStartPage ? DESKTOP_TICKER_HEIGHT : 0), DESKTOP_PANORAMA_HEIGHT, DESKTOP_TICKER_HEIGHT + (isStartPage ? DESKTOP_HEADER_HEIGHT : 0)];
  var _ref2 = _slicedToArray(_ref, 3);
  panoramaTopPosition = _ref2[0];
  panoramaHeight = _ref2[1];
  lockBreakPoint = _ref2[2];
  window.setTimeout(function () {
    startPosition = window.pageYOffset;
    lastPosition = startPosition;
    if (startPosition === 0) {
      lockPanorama();
    } else {
      window.addEventListener("scroll", smoothScroll);
    }
  }, 5000);
}
function lockPanorama() {
  siteWrapper.classList.remove("panorama-scrolls-out");
  siteWrapper.classList.add("panorama-is-scrolled-out");
  window.removeEventListener("scroll", smoothScroll);
  panorama.style.top = "";
  siteBody.style.margin = "";
  if (stickyOutsider) {
    stickyOutsider.style.top = "".concat(panoramaTopPosition + 12, "px");
    stickyOutsider.style.position = "sticky";
  }
  if (nativeBanner) {
    nativeBanner.style.top = CHANNEL_TABLET ? "0px" : "".concat(DESKTOP_HEADER_HEIGHT, "px");
    nativeBanner.style.position = "sticky";
    nativeBanner.style.padding = "0";
    nativeArticle.style.paddingTop = "0";
    articleMain.style.paddingBottom = "0";
  }
}
function smoothScroll() {
  var currentPosition = window.pageYOffset;
  var isScrollingDown = currentPosition > lastPosition;
  var isScrollingUp = currentPosition < lastPosition;
  if (isScrollingDown) {
    if (!isPanoramaScrollingOut) {
      scrollOutStartPosition = currentPosition;
      isPanoramaScrollingOut = true;
      siteWrapper.classList.add("panorama-scrolls-out");
      var topValue = panoramaTopPosition + currentPosition;
      panorama.style.top = "".concat(topValue, "px");
      siteBody.style.margin = "".concat(panoramaHeight, "px 0 0");
      attachItems();
    } else if (currentPosition - scrollOutStartPosition > panoramaHeight || panorama.getBoundingClientRect().bottom <= panoramaTopPosition) {
      lockPanorama();
    }
  } else if (isScrollingUp) {
    var diff = (lastPosition - currentPosition) * 1.5;
    if (currentPosition <= lockBreakPoint) {
      lockPanorama();
    }
    if (isPanoramaScrollingOut && currentPosition - diff <= scrollOutStartPosition) {
      isPanoramaScrollingOut = false;
      siteWrapper.classList.remove("panorama-scrolls-out");
      panorama.style.top = "";
      siteBody.style.margin = "";
      detatchItems();
    }
  }
  lastPosition = currentPosition;
}
function attachItems() {
  if (stickyOutsider) {
    var firstOutsider = document.getElementsByClassName("outsider-ad--first")[0];
    if ((firstOutsider === null || firstOutsider === void 0 ? void 0 : firstOutsider.getBoundingClientRect) > panorama.getBoundingClientRect().bottom) {
      stickyOutsider.style.top = "0px";
    } else {
      var isSectionPage = !!document.getElementsByClassName("section-page")[0];
      var isVerticalPage = !!document.getElementsByClassName("vertical-page")[0];
      stickyOutsider.style.width = isStartPage || isSectionPage || isVerticalPage ? "300px" : "316px";
      stickyOutsider.style.top = "".concat(stickyOutsider.offsetTop - 12, "px");
      stickyOutsider.style.position = "absolute";
    }
  }
  if (nativeBanner) {
    nativeBanner.style.top = "".concat(nativeBanner.offsetTop - nativeBanner.offsetHeight + BASE_GUTTER * 2, "px");
    nativeBanner.style.position = "absolute";
    nativeBanner.style.width = "".concat(nativeArticle.offsetWidth, "px");
    nativeArticle.style.paddingTop = "".concat(BASE_GUTTER * 2, "px");
    articleMain.style.paddingBottom = "".concat(BASE_GUTTER * 2, "px");
  }
}
function detatchItems() {
  if (stickyOutsider) {
    stickyOutsider.style.top = isStartPage ? "352px" : "320px";
    stickyOutsider.style.position = "sticky";
  }
  if (nativeBanner) {
    nativeBanner.style.top = "".concat(panoramaHeight + (CHANNEL_DESKTOP ? DESKTOP_HEADER_HEIGHT : 0), "px");
    nativeBanner.style.position = "sticky";
    nativeBanner.style.padding = "0";
    nativeArticle.style.paddingTop = "0";
    articleMain.style.paddingBottom = "0";
  }
}