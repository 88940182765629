export default function trackStartPageViewImpression() {
  var activateTracking = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  if (!activateTracking || !CHANNEL_MOBILE) return;
  var containers = document.getElementsByClassName("js_latest-live-test");
  if (!containers.length) return;
  var intersectionThreshold = 0.3;
  var observer = new window.IntersectionObserver(viewPortUpdate, {
    threshold: intersectionThreshold
  });
  for (var i = 0; i < containers.length; i++) {
    observer.observe(containers[i]);
  }
  function viewPortUpdate(entries) {
    entries.forEach(function (_ref) {
      var target = _ref.target,
        intersectionRatio = _ref.intersectionRatio;
      if (intersectionRatio > 0) {
        var position = target.getAttribute("data-position");
        var variant = target.getAttribute("data-variant");
        var containerType = target.getAttribute("data-container");
        pushDataLayer(target, position, variant, containerType);
        observer.unobserve(target);
      }
    });
  }
}
function pushDataLayer(target, position, variant, containerType) {
  var obj = {
    event: "Graphics Click",
    eventInfo: {
      label: "".concat(containerType, " Impression ").concat(variant),
      position: position
    }
  };
  window.dataLayer.push(obj);
}