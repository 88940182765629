export default function trackStartPageClick() {
  var activateTracking = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  if (!activateTracking || !CHANNEL_MOBILE) return;
  var containers = document.getElementsByClassName("js_latest-live-test");
  if (!containers.length) return;
  addListener(containers);
}
function addListener(items) {
  if (!(items !== null && items !== void 0 && items.length)) return;
  var _loop = function _loop() {
    var position = items[i].getAttribute("data-position");
    var variant = items[i].getAttribute("data-variant");
    var containerType = items[i].getAttribute("data-container");
    items[i].addEventListener("click", function (e) {
      pushDataLayer(e, position, variant, containerType);
    });
  };
  for (var i = 0; i < items.length; i++) {
    _loop();
  }
}
function pushDataLayer(e, position, variant, containerType) {
  var link = e.target.closest("a");
  if (!link) return;
  window.dataLayer.push({
    event: "Graphics Click",
    eventInfo: {
      label: "Click on ".concat(containerType, " ").concat(variant),
      position: "".concat(position, " - ").concat(link.href)
    }
  });
}