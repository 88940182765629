import { deformatNumber, deformatString } from "../../helpers/deformat";
import { updateQueryParams } from "../../helpers/updateQueryParams.js";
var ASCENDING_MODIFIER = "instrument-table__column--ascending";
var CHILD_TABLE_CLASS = "js_instrument-table--child";
var COLUMN_CLASS = "js_instrument-table__column";
var DESCENDING_MODIFIER = "instrument-table__column--descending";
var SUMMARY_ROW_CLASS = "instrument-table__summary-row";
var TABLE_HEADER_CLASS = "instrument-table__header";
export function getAllTableParts(firstTable) {
  if (!firstTable) return [];
  return [].concat.apply([firstTable], [].slice.call(firstTable.parentElement.getElementsByClassName(CHILD_TABLE_CLASS)).filter(function (el) {
    return el.dataset.tab === firstTable.dataset.tab;
  }));
}
export function getColumnHeaders(tables) {
  if (!(tables !== null && tables !== void 0 && tables.length)) return [];
  return [].concat.apply([], tables.map(function (t) {
    return [].slice.call(t.getElementsByClassName(COLUMN_CLASS));
  }));
}
export function getTableRows(tables) {
  if (!(tables !== null && tables !== void 0 && tables.length)) return [];
  return [].concat.apply([], tables.map(function (t) {
    var trs = t === null || t === void 0 ? void 0 : t.getElementsByTagName("tr");
    if (!trs) return [];
    return [].slice.call(trs);
  })).filter(function (r) {
    return !r.classList.contains(TABLE_HEADER_CLASS) && !r.classList.contains(SUMMARY_ROW_CLASS);
  });
}
export function markChanges(tables) {
  if (!(tables !== null && tables !== void 0 && tables.length)) return;
  tables.forEach(function (t, index) {
    if (index === 0) {
      markChangesInTablePart(t);
    } else {
      window.setTimeout(function () {
        markChangesInTablePart(t);
      }, 0);
    }
  });
}
function markChangesInTablePart(table) {
  if (!table) return;
  var columns = [].concat.apply([], table.getElementsByClassName(COLUMN_CLASS)).filter(function (th) {
    return th.dataset.markChange === "true";
  });
  var trs = [].concat.apply([], table.getElementsByTagName("tr")).filter(function (tr) {
    return !tr.classList.contains(TABLE_HEADER_CLASS) && !tr.classList.contains(SUMMARY_ROW_CLASS);
  });
  if (!(trs !== null && trs !== void 0 && trs.length) || !(columns !== null && columns !== void 0 && columns.length)) return;
  trs.forEach(function (tr) {
    var tds = [].concat.apply([], tr.getElementsByTagName("td"));
    columns.forEach(function (th) {
      var td = CHANNEL_MOBILE || CHANNEL_TABLET ? tds[th.dataset.index - 1] : tds[th.dataset.index];
      if (!td) return;
      var value = td.innerHTML === "-" ? td.innerHTML : td.innerHTML.replace(/\+|-|%/g, "");
      var shouldMarkChange = value === "" || value === "-" || value === "0,00" || value === "0" ? false : true;
      if (shouldMarkChange) td.classList.add("i-t__c--".concat(td.innerText.indexOf("-") >= 0 ? "n" : "p"));
    });
  });
}
export function setupColumnHeaders(columnHeaders, tableData, updateTable) {
  if (!Array.isArray(columnHeaders) || !Array.isArray(tableData) || !updateTable) return;
  columnHeaders.forEach(function (th) {
    th.addEventListener("click", function () {
      var isColumnSorted = th.classList.contains(DESCENDING_MODIFIER) || th.classList.contains(ASCENDING_MODIFIER);
      if (isColumnSorted) {
        th.classList.toggle(DESCENDING_MODIFIER);
        th.classList.toggle(ASCENDING_MODIFIER);
        tableData.reverse();
      } else {
        columnHeaders.forEach(function (header) {
          header.classList.remove(DESCENDING_MODIFIER, ASCENDING_MODIFIER);
        });
        // Text columns should initially be sorted ascending while others should be sorted descending
        var descending = th.dataset.format !== "text";
        th.classList.add(descending ? DESCENDING_MODIFIER : ASCENDING_MODIFIER);
        sortTableData(tableData, parseInt(th.dataset.index) + 1, descending);
      }
      updateTable();
    });
  });
}
export function setupBackendSorting(columnHeaders) {
  columnHeaders === null || columnHeaders === void 0 || columnHeaders.forEach(function (th) {
    th.addEventListener("click", function () {
      var field = th.dataset.field;
      var columnIsSorted = th.classList.contains(ASCENDING_MODIFIER) || th.classList.contains(DESCENDING_MODIFIER);
      var descending = columnIsSorted ? th.classList.contains(ASCENDING_MODIFIER) : field !== "name";
      updateQueryParams({
        field: field,
        desc: descending
      });
    });
  });
}
export function addRowClickEvents(tableRows, scrollableRows) {
  if (!(tableRows !== null && tableRows !== void 0 && tableRows.length)) return;
  tableRows.forEach(function (tr, i) {
    var firstCell = tr.getElementsByTagName("TD")[0];
    var clickArea = scrollableRows ? scrollableRows[i] : tr;
    clickArea.addEventListener("click", function (e) {
      var _firstCell$firstChild, _firstCell$firstChild2;
      var href = (_firstCell$firstChild = firstCell === null || firstCell === void 0 || (_firstCell$firstChild2 = firstCell.firstChild) === null || _firstCell$firstChild2 === void 0 ? void 0 : _firstCell$firstChild2.href) !== null && _firstCell$firstChild !== void 0 ? _firstCell$firstChild : null;
      if (href) {
        if (e.metaKey) {
          window.open(href, "_blank");
        } else {
          window.location = href;
        }
        e.preventDefault();
      }
    });
  });
}
export function getDeformaters(columnHeaders) {
  if (!(columnHeaders !== null && columnHeaders !== void 0 && columnHeaders.length)) return;
  var deformation = {};
  columnHeaders.forEach(function (th, index) {
    var format = th.dataset.format;
    switch (format) {
      case "integer":
      case "decimal":
      case "percentage":
        deformation[index] = deformatNumber;
        break;
      default:
        deformation[index] = deformatString;
    }
  });
  return deformation;
}
export function getTableBody(tables, tableIndex, adCount) {
  if (!(tables !== null && tables !== void 0 && tables.length)) return [];
  if (tableIndex > adCount || tableIndex >= tables.length) {
    return tables[tables.length - 1].getElementsByTagName("tbody")[0];
  }
  return tables[tableIndex].getElementsByTagName("tbody")[0];
}
function sortTableData(tableData, columnIndex, descending) {
  if (!(tableData !== null && tableData !== void 0 && tableData.length)) return;
  tableData.sort(function (a, b) {
    var comparison = a[columnIndex] < b[columnIndex];
    return descending === comparison ? 1 : -1;
  });
}