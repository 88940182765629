import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export function pushSearchEventToDataLayer(_ref) {
  var event = _ref.event,
    componentName = _ref.componentName,
    searchQuery = _ref.searchQuery,
    previewLinkEl = _ref.previewLinkEl,
    type = _ref.type;
  var isStockSearch = componentName === "stock search";
  var baseData = {
    event: event,
    eventParams: {
      category: "search",
      searchQuery: searchQuery
    },
    filter: isStockSearch ? {
      categories: "Aktier, Fonder, Index, Valutor, Råvaror, Räntor"
    } : undefined,
    engagement: {
      type: type,
      component: {
        position: undefined,
        name: componentName,
        index: "1",
        range: undefined,
        id: undefined,
        type: undefined,
        element: undefined
      }
    }
  };
  window.dataLayer.push(_objectSpread(_objectSpread({}, baseData), {}, {
    engagement: _objectSpread(_objectSpread({}, baseData.engagement), previewLinkEl && {
      destination: {
        url: previewLinkEl.href,
        title: previewLinkEl.innerText,
        type: "internal",
        id: undefined
      }
    })
  }));
}