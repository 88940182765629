export default function plusProButton() {
  var siteHeaderButton = document.getElementsByClassName("js_button-plus-pro")[0];
  if (!siteHeaderButton) return;
  siteHeaderButton.addEventListener("click", pushDataLayer);
}
function pushDataLayer(e) {
  var href = e.target.getAttribute("href");

  //old
  window.dataLayer.push({
    event: "Menu Click",
    eventInfo: {
      position: "Top Menu",
      label: "+Pro"
    }
  });

  // new
  window.dataLayer.push({
    event: "navigation link click",
    eventParams: {
      category: "navigation"
    },
    engagement: {
      type: "click",
      destination: {
        title: "Di Plus Pro",
        url: new URL(href, window.location.origin).toString(),
        type: "internal"
      },
      component: {
        position: "Top Menu",
        name: "Di Plus Pro",
        type: "top menu"
      }
    }
  });
}