import throttle from "./throttle";
var ads = {};
var validAds = ["panorama_top", "articlemodule", "module", "insider", "outsider"];
export default function init(Bau) {
  Bau.events.subscribe("resize", resizeAd);
  window.addEventListener("resize", throttle(resizeAds, 50));
}
function resizeAds() {
  Object.keys(ads).forEach(function (id) {
    return resizeAd(ads[id]);
  });
}
function resizeAd(adData) {
  if (!adData || !adData.name || validAds.indexOf(adData.name) === -1) return;
  var adId = adData.id;
  ads[adId] = adData;
  if (adData.isEmpty) return;
  var element = document.getElementById(adId);
  if (!element) return;
  var inner = element.firstElementChild;
  if (!inner) return;
  var _adData$size = adData.size,
    width = _adData$size.width,
    height = _adData$size.height;
  var maxWidth = element.parentElement.offsetWidth;
  if (isNaN(maxWidth)) return;
  if (width <= maxWidth) {
    inner.style.removeProperty("webkitTransform");
    inner.style.removeProperty("transform");
    element.style.height = "auto";
    return;
  }
  var scale = maxWidth / width;
  var adHeight = height * scale;
  inner.style.webkitTransformOrigin = inner.style.transformOrigin = "left top";
  inner.style.webkitTransform = inner.style.transform = "scale(".concat(scale, ")");
  element.style.height = "".concat(adHeight, "px");
  element.style.minHeight = "auto";
}