import closeOtherHeaderActions from "../helpers/closeOtherHeaderActions";
import scroll from "../helpers/scroll";
export default function userMenu() {
  var isDesktopStart = window.location.pathname === "/" && window.Di.contentType === "sectionPage" && CHANNEL_DESKTOP;
  var headerClasses = isDesktopStart ? ["js_site-header-big", "js_site-header"] : ["js_site-header"];
  addEventListeners(headerClasses, "js_user-toggle", "js_user-menu");
}
function addEventListeners(headerClasses, toggleClass, userMenuClass) {
  for (var i = 0; i < headerClasses.length; i++) {
    var siteHeader = document.getElementsByClassName(headerClasses[i])[0];
    if (!siteHeader) return;
    var toggleEl = siteHeader.getElementsByClassName(toggleClass)[0];
    if (!toggleEl) return;
    var userMenuEl = siteHeader.getElementsByClassName(userMenuClass)[0];
    if (!userMenuEl) return;
    addListener(toggleEl, userMenuEl, siteHeader);
  }
}
function addListener(toggle, menu, siteHeader) {
  toggle.addEventListener("click", function (event) {
    event.stopPropagation();
    handleUserMenu(menu, siteHeader);
  });
}
function handleUserMenu(userMenuModal, siteHeader) {
  userMenuModal.classList.toggle("hidden");
  closeOtherHeaderActions("user", siteHeader);
  handleOverlay(userMenuModal);
}
function handleOverlay(userMenuModal) {
  var overlay = document.getElementsByClassName("js_site-overlay")[0];
  var isHidden = overlay === null || overlay === void 0 ? void 0 : overlay.classList.contains("hidden");
  if (overlay) {
    if (isHidden) {
      overlay.classList.remove("hidden");
      scroll.add(removeOnScroll, true);
      if (!overlay.dataset.listenerAdded) {
        overlay.addEventListener("click", function () {
          overlay.classList.add("hidden");
          userMenuModal.classList.add("hidden");
        });
        overlay.dataset.listenerAdded = "true";
      }
    } else {
      overlay.classList.add("hidden");
    }
  }
  function removeOnScroll(_ref) {
    var scrollY = _ref.scrollY,
      lastScrollY = _ref.lastScrollY;
    if (scrollY !== lastScrollY) {
      overlay.classList.add("hidden");
      userMenuModal.classList.add("hidden");
      scroll.remove(removeOnScroll);
    }
  }
}