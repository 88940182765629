import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { addRevealIntersectionObserver } from "./reveal";
import forEach from "../helpers/forEach";
import Parallax from "./parallax";
import scroll from "../helpers/scroll";
var SITEHEADER_HEIGHT = 48;
export default function storytelling() {
  var heroImg = document.getElementsByClassName("image__placeholder")[0];
  if (heroImg) {
    // eslint-disable-next-line no-new
    new Parallax(heroImg, {
      scale: CHANNEL_MOBILE ? 1.05 : 1.4,
      // reduce stutter
      orientation: "down",
      overflow: !CHANNEL_MOBILE,
      maxTransition: 0,
      minTransition: 50
    });
  }
  var quotes = document.getElementsByClassName("quote");
  addRevealIntersectionObserver(quotes, {
    duration: 0.35,
    transition: {
      opacity: true
    },
    occurOnce: true,
    fadeIn: true
  });
  var siteHeader = document.getElementsByClassName("js_site-header__menu")[0];
  if (!siteHeader) return;
  var isDigitalPage = !!document.getElementsByClassName("digital")[0];
  var CLASS = "site-header__menu--background-color".concat(isDigitalPage ? "-digital" : "");
  var backgroundColorActivated = false;
  if (isDigitalPage || window.scrollY >= SITEHEADER_HEIGHT) siteHeader.classList.add(CLASS);
  if (isDigitalPage) return;
  scroll.add(handleScroll);
  function handleScroll(_ref) {
    var scrollY = _ref.scrollY,
      lastScrollY = _ref.lastScrollY;
    if (scrollY < SITEHEADER_HEIGHT) {
      // safari fix. You can scroll -px for some reason.
      backgroundColorActivated = false;
      siteHeader.classList.remove(CLASS);
      lastScrollY = scrollY;
      return;
    }
    var scrollingDown = scrollY > lastScrollY;
    var scrollingUp = scrollY <= SITEHEADER_HEIGHT && scrollY < lastScrollY;
    if (!backgroundColorActivated && scrollingDown) {
      backgroundColorActivated = true;
      siteHeader.classList.add(CLASS);
      lastScrollY = scrollY;
    } else if (backgroundColorActivated && scrollingUp) {
      backgroundColorActivated = false;
      siteHeader.classList.remove(CLASS);
    }
  }
  var searchToggle = document.getElementsByClassName("js_search-toggle")[0];
  var menuToggle = document.getElementsByClassName("js_menu-toggle")[0];
  var userToggle = document.getElementsByClassName("js_user-toggle")[0];
  var previousMenuTrigger = null;
  var siteHeaderToggles = [searchToggle, menuToggle, userToggle];
  forEach(siteHeaderToggles, addListener);
  function addListener(toggle) {
    if (toggle) {
      toggle.addEventListener("click", function (_ref2) {
        var target = _ref2.target;
        handleToggleClick(target);
        previousMenuTrigger = target;
      });
    }
  }
  function handleToggleClick(eventTarget) {
    if (activateSiteHeaderClick(eventTarget)) {
      handleOutsideMenuItemsClick();
    } else if (siteHeaderActionsClick(eventTarget)) {
      if (closeUserMenuClick(eventTarget)) {
        siteHeader.classList.remove(CLASS);
      }
    } else {
      handleOutsideMenuItemsClick();
    }
  }
  function handleOutsideMenuItemsClick() {
    var siteHeaderItems = getSiteHeaderItems();
    window.document.addEventListener("click", function (evt) {
      if (!backgroundColorActivated && !siteHeaderItems.includes(evt.target) && siteHeader.classList.contains(CLASS)) {
        siteHeader.classList.remove(CLASS);
        previousMenuTrigger = null;
        var userMenu = document.getElementsByClassName("js_user-menu")[0];
        if (!(userMenu !== null && userMenu !== void 0 && userMenu.classList.contains("hidden"))) {
          userMenu.classList.add("hidden");
        }
      }
    });
  }
  function closeUserMenuClick(eventTarget) {
    return eventTarget === userToggle && previousMenuTrigger === userToggle;
  }
  function siteHeaderActionsClick(eventTarget) {
    return !backgroundColorActivated && [searchToggle, menuToggle, userToggle].includes(eventTarget);
  }
  function activateSiteHeaderClick(eventTarget) {
    if (!backgroundColorActivated && eventTarget !== userToggle && (eventTarget === previousMenuTrigger || previousMenuTrigger === null)) {
      siteHeader.classList.toggle(CLASS);
      return true;
    } else if (!backgroundColorActivated && eventTarget !== userToggle && !siteHeader.classList.contains(CLASS)) {
      siteHeader.classList.add(CLASS);
      return true;
    }
    return false;
  }
  function getSiteHeaderItems() {
    var _megaMenu$children, _megaMenuGroup$childr, _megaMenuGroupHeader$, _searchMenu$children, _siteHeaderActions$ch, _siteHeaderInner$chil;
    var megaMenu = document.getElementsByClassName("mega-menu__content")[0];
    var megaMenuGroup = megaMenu === null || megaMenu === void 0 ? void 0 : megaMenu.getElementsByClassName("mega-menu__group")[0];
    var megaMenuGroupHeader = megaMenu === null || megaMenu === void 0 ? void 0 : megaMenu.getElementsByClassName("mega-menu__group-header")[0];
    var searchMenu = document.getElementsByClassName("search-form")[0];
    var siteHeaderActions = document.getElementsByClassName("header-actions")[0];
    var siteHeaderInner = document.getElementsByClassName("site-header__menu-inner")[0];
    var items = [megaMenu, megaMenuGroup, megaMenuGroupHeader, menuToggle, siteHeaderInner, searchMenu, searchToggle, userToggle].concat(_toConsumableArray((_megaMenu$children = megaMenu === null || megaMenu === void 0 ? void 0 : megaMenu.children) !== null && _megaMenu$children !== void 0 ? _megaMenu$children : {}), _toConsumableArray((_megaMenuGroup$childr = megaMenuGroup === null || megaMenuGroup === void 0 ? void 0 : megaMenuGroup.children) !== null && _megaMenuGroup$childr !== void 0 ? _megaMenuGroup$childr : {}), _toConsumableArray((_megaMenuGroupHeader$ = megaMenuGroupHeader === null || megaMenuGroupHeader === void 0 ? void 0 : megaMenuGroupHeader.children) !== null && _megaMenuGroupHeader$ !== void 0 ? _megaMenuGroupHeader$ : {}), _toConsumableArray((_searchMenu$children = searchMenu === null || searchMenu === void 0 ? void 0 : searchMenu.children) !== null && _searchMenu$children !== void 0 ? _searchMenu$children : {}), _toConsumableArray((_siteHeaderActions$ch = siteHeaderActions === null || siteHeaderActions === void 0 ? void 0 : siteHeaderActions.children) !== null && _siteHeaderActions$ch !== void 0 ? _siteHeaderActions$ch : {}), _toConsumableArray((_siteHeaderInner$chil = siteHeaderInner === null || siteHeaderInner === void 0 ? void 0 : siteHeaderInner.children) !== null && _siteHeaderInner$chil !== void 0 ? _siteHeaderInner$chil : {}));
    return items;
  }
}