import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import capitalize from "../helpers/capitalize";
import forEach from "../helpers/forEach";
import theoPlayer from "./theoPlayer";
var scrollPosition = 0;
var lastViewedArticle = null;
var articlesSeenInView = [];
var continuousArticleHash = "#cs";
export function initContinuousScroll() {
  var _document$getElements, _document$getElements2;
  var continuousArticles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document.getElementsByClassName("js_continuous-article");
  if (!("IntersectionObserver" in window)) return;
  var originalArticle = document.getElementsByClassName("js_first-article")[0];
  if (!originalArticle) return;
  var nativeArticle = document.getElementsByClassName("native-article")[0];
  if (!nativeArticle && !(continuousArticles !== null && continuousArticles !== void 0 && continuousArticles.length)) return;
  var originalArticleId = originalArticle.getAttribute("data-id");
  if (!articlesSeenInView.includes(originalArticleId)) {
    articlesSeenInView.push(originalArticleId);
  }
  var menuHeight = (_document$getElements = (_document$getElements2 = document.getElementsByClassName("js_site-header")[0]) === null || _document$getElements2 === void 0 ? void 0 : _document$getElements2.clientHeight) !== null && _document$getElements !== void 0 ? _document$getElements : 0;
  var windowInnerHeight = window.innerHeight - menuHeight;
  var observerIn = new window.IntersectionObserver(viewPortUpdateIn, {
    rootMargin: "0px 0px -".concat(windowInnerHeight, "px 0px")
  });
  var observerOut = new window.IntersectionObserver(viewPortUpdateOut, {
    rootMargin: "-".concat(windowInnerHeight, "px 0px 0px 0px ")
  });
  var entryElements = nativeArticle ? [originalArticle, nativeArticle].concat(_toConsumableArray(continuousArticles)) : [originalArticle].concat(_toConsumableArray(continuousArticles));
  forEach(entryElements, function (el) {
    if ((el === null || el === void 0 ? void 0 : el.getAttribute("data-observed")) === "true") return;
    observeElement(el);
  });
  function viewPortUpdateIn(entries) {
    var _entry$boundingClient;
    var entry = getIntersectingEntry(entries);
    if ((entry === null || entry === void 0 || (_entry$boundingClient = entry.boundingClientRect) === null || _entry$boundingClient === void 0 ? void 0 : _entry$boundingClient.y) < 0) return;
    viewPortUpdate(entry);
  }
  function viewPortUpdateOut(entries) {
    var _entry$boundingClient2;
    var entry = getIntersectingEntry(entries);
    if ((entry === null || entry === void 0 || (_entry$boundingClient2 = entry.boundingClientRect) === null || _entry$boundingClient2 === void 0 ? void 0 : _entry$boundingClient2.y) > 0) return;
    viewPortUpdate(entry);
  }
  function getIntersectingEntry(entries) {
    var filteredEntries = entries.filter(function (e) {
      return e.isIntersecting;
    });
    return filteredEntries[filteredEntries.length - 1];
  }
  function viewPortUpdate(entry) {
    var articleEl = entry === null || entry === void 0 ? void 0 : entry.target;
    if (!articleEl) return;
    theoPlayer(articleEl);
    var url, title;
    if (entry) {
      var isOriginalArticle = articleEl === originalArticle;
      var _updateElements = updateElements(articleEl, isOriginalArticle),
        newUrl = _updateElements.newUrl,
        newTitle = _updateElements.newTitle;
      url = newUrl;
      title = newTitle;
    }
    var articleId = articleEl.getAttribute("data-id");
    if (!entry) return;
    var scrollDirection = getScrollDirection();
    if (lastViewedArticle === articleId || articleId === originalArticleId && scrollDirection === "down") return;
    updateMetadata(articleEl, articleId, url, title, scrollDirection);
  }
  function getScrollDirection() {
    var direction = document.body.getBoundingClientRect().top > scrollPosition ? "up" : "down";
    scrollPosition = document.body.getBoundingClientRect().top;
    return direction;
  }
  function observeElement(element) {
    observerIn.observe(element);
    observerOut.observe(element);
    element.setAttribute("data-observed", "true");
  }
}
export function clearPreviousContinuousScroll() {
  if (window.location.hash === continuousArticleHash) {
    window.history.replaceState(null, null, window.location.pathname + window.location.search);
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
    var listener = window.addEventListener("load", function () {
      window.scrollTo(0, 0);
      this.window.removeEventListener("load", listener);
    });
  }
}
function updateElements(articleEl, isOriginalArticle) {
  var _newTitle;
  var newUrl = articleEl.getAttribute("data-url");
  var newTitle = articleEl.getAttribute("data-title");
  if (articleEl.classList.contains("native-article") && !((_newTitle = newTitle) !== null && _newTitle !== void 0 && _newTitle.startsWith("ANNONS:"))) {
    newTitle = "ANNONS: ".concat(newTitle);
  }
  window.history.replaceState(null, null, "".concat(newUrl).concat(isOriginalArticle ? "" : continuousArticleHash));
  document.title = newTitle;
  return {
    newUrl: newUrl,
    newTitle: newTitle
  };
}
function updateMetadata(articleEl, articleId, url, title, scrollDirection) {
  var _tags$split, _tags$split2;
  var _articleEl$dataset = articleEl.dataset,
    accessRestriction = _articleEl$dataset.accessRestriction,
    product = _articleEl$dataset.product,
    publicationTime = _articleEl$dataset.publicationTime,
    section = _articleEl$dataset.section,
    tags = _articleEl$dataset.tags,
    advertiserName = _articleEl$dataset.advertiserName;
  var isNativeAd = articleEl.classList.contains("native-article");
  var brand = product === "digital" ? ["Di", "Digital"] : ["Di", "DISE"];
  var subBrand = product === "digital" ? "Digital" : "Dagens Industri";
  if (!articlesSeenInView.includes(articleId)) {
    articlesSeenInView.push(articleId);
  }

  //old
  var oldPageView = Object.assign({}, window.Di.oldGtmData);
  var environmentInfo = oldPageView.environmentInfo,
    pageInfo = oldPageView.pageInfo;
  environmentInfo.brand = brand;
  pageInfo.accessRestriction = isNativeAd ? "Free" : accessRestriction;
  pageInfo.id.value = articleId;
  pageInfo.page = url;
  pageInfo.pageType = isNativeAd ? "Native Ad Page" : "Article Page";
  pageInfo.title = title;
  pageInfo.sections = getPageInfoSection(section);
  pageInfo.publicationDate = publicationTime.slice(0, 10);
  pageInfo.tags = (_tags$split = tags.split(",")) !== null && _tags$split !== void 0 ? _tags$split : [];
  pageInfo.trigger = scrollDirection === "up" ? "Virtual Page Load Up" : "Virtual Page Load";
  window.dataLayer.push(oldPageView);

  //new
  var pageView = Object.assign({}, window.Di.gtmData);
  var org = pageView.org,
    content = pageView.content;
  org.subBrand = subBrand;
  org.service = "main content";
  content.contentAccessStatus = isNativeAd ? "open" : getContentAccessStatusFromOld(accessRestriction);
  content.id.value = articleId;
  content.location = window.location.href.replace(continuousArticleHash, "");
  content.type = isNativeAd ? "native ad" : "article";
  content.title = title;
  content.section = getPageInfoSection(section);
  content.publicationDate = publicationTime.slice(0, 10);
  content.tags = (_tags$split2 = tags.split(",")) !== null && _tags$split2 !== void 0 ? _tags$split2 : [];
  content.trigger = scrollDirection === "up" ? "virtual page load up" : "virtual page load";
  content.articleIndex = 1 + articlesSeenInView.indexOf(articleId);
  if (isNativeAd) {
    content.advertiserName = advertiserName;
  }
  window.dataLayer.push(pageView);
  lastViewedArticle = articleId;
}
function getPageInfoSection(section) {
  if (!section) return ["Annons"];
  var trimmedSection = section.replace("di.section.", "");
  return [capitalize(trimmedSection)];
}
function getContentAccessStatusFromOld(oldStatus) {
  var _map$oldStatus$toLowe;
  var map = {
    locked: "locked",
    free_logged_in: "limited",
    //eslint-disable-line camelcase
    free: "open",
    none: "open"
  };
  return (_map$oldStatus$toLowe = map[oldStatus.toLowerCase()]) !== null && _map$oldStatus$toLowe !== void 0 ? _map$oldStatus$toLowe : "open";
}