import moment from "moment";
import scroll from "../helpers/scroll";
export default function plusProPopup() {
  var _window$Di$userInfo, _window$localStorage;
  var isStartPage = window.location.pathname === "/";
  var diPlusProEntitled = (_window$Di$userInfo = window.Di.userInfo) === null || _window$Di$userInfo === void 0 || (_window$Di$userInfo = _window$Di$userInfo.entitlements) === null || _window$Di$userInfo === void 0 ? void 0 : _window$Di$userInfo.toLowerCase().includes("dipluspro");
  if (!(isStartPage && diPlusProEntitled)) return;
  var popup = document.getElementsByClassName("js_plus-pro-popup")[0];
  if (!popup) return;
  var plusProPopupItem = JSON.parse(window.Di.hasLocalStorage && ((_window$localStorage = window.localStorage) === null || _window$localStorage === void 0 ? void 0 : _window$localStorage.getItem("plusProPopupItem")));
  if (plusProPopupItem !== null && plusProPopupItem !== void 0 && plusProPopupItem.shown || plusProPopupItem !== null && plusProPopupItem !== void 0 && plusProPopupItem.visitedPlusPro) {
    shouldShowPopup(plusProPopupItem, popup);
  } else {
    showPopup(popup, plusProPopupItem);
  }
  var popupCloseButton = popup.getElementsByClassName("plus-pro-popup__close")[0];
  if (popupCloseButton) {
    popupCloseButton.addEventListener("click", function () {
      popup.classList.add("hidden");
      updateLocalStorage();
    });
  }
  function addScrollBehaviour(_ref) {
    var scrollY = _ref.scrollY;
    if (scrollY > 30) {
      fadeOut(popup);
    } else if (scrollY === 0) {
      fadeIn(popup);
    }
  }
  if (shouldUseScroll(plusProPopupItem)) {
    scroll.add(addScrollBehaviour);
  }
}
function fadeOut(popup) {
  popup.classList.add("fade-out");
  setTimeout(function () {
    popup.classList.add("hidden");
    popup.classList.remove("fade-out");
  }, 500);
}
function fadeIn(popup) {
  setTimeout(function () {
    popup.classList.remove("hidden");
    popup.classList.remove("fade-out");
  }, 500);
}
function getStorageItem(item) {
  var shown = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var today = moment().format("YYYY-MM-DD");
  return JSON.stringify({
    shown: shown,
    creationDate: today,
    timesShown: item !== null && item !== void 0 && item.timesShown ? item.timesShown + 1 : 1,
    visitedPlusPro: (item === null || item === void 0 ? void 0 : item.visitedPlusPro) || false,
    lastActive: (item === null || item === void 0 ? void 0 : item.lastActive) || null
  });
}
function setStorageItem(plusProPopupItem, shown) {
  if (window.localStorage) window.localStorage.setItem("plusProPopupItem", getStorageItem(plusProPopupItem, shown));
}
function showPopup(popup, storageItem, removeSpanEl, shown) {
  if (removeSpanEl) {
    var heading = popup.getElementsByClassName("plus-pro-popup__heading")[0];
    var spanEl = heading === null || heading === void 0 ? void 0 : heading.getElementsByTagName("span")[0];
    spanEl === null || spanEl === void 0 || spanEl.remove();
  }
  popup.classList.remove("hidden");
  setStorageItem(storageItem, shown);
}
function shouldShowPopup(plusProPopupItem, popup) {
  if (!plusProPopupItem.visitedPlusPro) {
    var creationDate = plusProPopupItem.creationDate;
    if (creationDate && moment().diff(creationDate, "days") >= 30) {
      showPopup(popup, plusProPopupItem, plusProPopupItem.timesShown >= 2);
    }
  } else if (plusProPopupItem.visitedPlusPro) {
    var lastActive = plusProPopupItem.lastActive;
    if (lastActive && moment().diff(lastActive, "days") >= 60) {
      showPopup(popup, plusProPopupItem, true, true);
    }
  }
}
function shouldUseScroll(plusProPopupItem) {
  if (!plusProPopupItem) return true;
  var shown = plusProPopupItem.shown,
    visitedPlusPro = plusProPopupItem.visitedPlusPro,
    creationDate = plusProPopupItem.creationDate,
    lastActive = plusProPopupItem.lastActive;
  var showAgain = shown && !visitedPlusPro && moment().diff(creationDate, "days") >= 30;
  var visitAgain = shown && visitedPlusPro && moment().diff(lastActive, "days") >= 60;
  return !shown || showAgain || visitAgain;
}
function updateLocalStorage(plusProPopupItem) {
  var shown = true;
  if (window.localStorage) window.localStorage.setItem("plusProPopupItem", getStorageItem(plusProPopupItem, shown));
}