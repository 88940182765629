var MS_PER_DAY = 60 * 60 * 24 * 1000;
var PERIODS = {
  "1w": MS_PER_DAY * 7,
  "1m": MS_PER_DAY * 30,
  "3m": MS_PER_DAY * 91,
  "1y": MS_PER_DAY * 365,
  "3y": MS_PER_DAY * 1096,
  "10y": MS_PER_DAY * 3653
};
export { formatLongDateTime, getCurrentTime, getTimestamp, MS_PER_DAY };
function formatLongDateTime(date) {
  var showHours = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var datePart = "".concat(date.getFullYear(), "-").concat(zeroPad(date.getMonth() + 1), "-").concat(zeroPad(date.getDate()));
  var timePart = "".concat(zeroPad(date.getHours()), ":").concat(zeroPad(date.getMinutes()));
  return showHours ? "".concat(datePart, " ").concat(timePart) : datePart;
}
function getCurrentTime() {
  var d = new Date();
  return "".concat(zeroPad(d.getHours()), ":").concat(zeroPad(d.getMinutes()));
}
function getTimestamp(period) {
  if (period === "1d") return;
  var date = new Date();
  var year = date.getFullYear();
  date = date.getTime();
  return period === "ytd" ? new Date(year, 0).getTime() : date - PERIODS[period];
}
function zeroPad(number) {
  if (number < 10) return "0".concat(number);
  return "".concat(number);
}