import scroll from "../helpers/scroll";
export default function initModal(modalEl) {
  if (!modalEl) return;
  var containerEl = modalEl.firstElementChild;
  containerEl.addEventListener("click", function (e) {
    e.stopPropagation();
  });
  return {
    open: open
  };
  function open() {
    modalEl.classList.remove("hidden");
    scroll.disableScroll();
    var closeEl = containerEl.getElementsByClassName("modal__close");
    var elements = [].concat([].slice.call(closeEl), modalEl);
    elements.forEach(function (el) {
      return el.addEventListener("click", close);
    });
    if (CHANNEL_DESKTOP) window.addEventListener("keyup", keyClose);
    function close(event) {
      if (event) event.stopPropagation();
      elements.forEach(function (el) {
        return el.removeEventListener("click", close);
      });
      if (CHANNEL_DESKTOP) window.removeEventListener("keyup", keyClose);
      scroll.enableScroll();
      modalEl.classList.add("hidden");
    }
    function keyClose(event) {
      if (event.key === "Escape") close();
    }
  }
}