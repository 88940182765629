import lazyLoadAds from "../helpers/lazyLoadAds";
import outsiderAds from "./outsiderAds";
import resizeAd from "../helpers/resizeAd";
window.Bau = window.Bau || [];
export default function init(bamData, continuousArticle) {
  var _bamData$adInfo$link, _bamData$adInfo;
  if (!bamData || window.Di.hasAdBlocker) return;
  var adsInfoUrl = (_bamData$adInfo$link = (_bamData$adInfo = bamData.adInfo) === null || _bamData$adInfo === void 0 ? void 0 : _bamData$adInfo.link) !== null && _bamData$adInfo$link !== void 0 ? _bamData$adInfo$link : null;
  if (adsInfoUrl) {
    window.Bau.push(function () {
      window.Bau.events.subscribe("slotRenderEnded", enableShowAdInfo);
    });
  }
  if (!CHANNEL_MOBILE) {
    adResizer();
    if (!continuousArticle && (CHANNEL_RESPONSIVE || CHANNEL_DESKTOP)) {
      outsiderAds();
    }
  }
  if (continuousArticle) {
    lazyLoadAds(continuousArticle);
  } else {
    lazyLoadAds(document.getElementsByClassName("site-wrapper")[0]);
  }
  function adResizer() {
    window.Bau.push(function () {
      resizeAd(window.Bau);
    });
  }
  function enableShowAdInfo(_ref) {
    var id = _ref.id,
      isEmpty = _ref.isEmpty;
    if (isEmpty || (id === null || id === void 0 ? void 0 : id.indexOf("panorama_top")) > -1) return;
    var ad = document.getElementById(id);
    if (!ad) return;
    ad.removeEventListener("click", showAdsInfo);
    ad.addEventListener("click", showAdsInfo);
  }
  function showAdsInfo(event) {
    if (event.target !== this) return;
    window.open(adsInfoUrl);
  }
}