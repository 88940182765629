var defaultLoadOffset = 400;
export default function lazyLoadAds(scope) {
  if (!scope) return;
  window.Bau.push(function () {
    window.Bau.events.subscribe("slotRenderEnded", handleEmptyAds);
  });
  var adSlots = scope.getElementsByClassName("bam-ad-slot");
  var slotLength = adSlots.length;
  var observer200 = setupObserver(200);
  var observerDefault = setupObserver(defaultLoadOffset);
  for (var i = 0, len = adSlots.length; i < len; i++) {
    var slotName = adSlots[i].getAttribute("data-slot-name");
    if (["module", "insider", "outsider", "mob"].includes(slotName)) {
      observer200.observe(adSlots[i]);
    } else {
      observerDefault.observe(adSlots[i]);
    }
  }
  function setupObserver(offset) {
    var intersectionObserver = new window.IntersectionObserver(viewPortUpdate, {
      rootMargin: "".concat(offset, "px 0px")
    });
    function viewPortUpdate(entries) {
      entries.forEach(function (entry) {
        if (entry.intersectionRatio > 0) {
          intersectionObserver.unobserve(entry.target);
          --slotLength;
          displayAd(entry.target);
          if (slotLength <= 0) {
            intersectionObserver.disconnect();
          }
        }
      });
    }
    return intersectionObserver;
  }
  function displayAd(adSlot) {
    window.Bau.push(function () {
      window.Bau.displayAds(adSlot);
    });
  }
  function handleEmptyAds(adData) {
    if (!adData || !adData.name || adData.name.indexOf("rich_media") >= 0 || !adData.isEmpty) return;
    var element = document.getElementById(adData.id);
    if (!element) return;
    var adContainer = element.closest(".ad");
    adContainer.parentElement.removeChild(adContainer);
  }
}